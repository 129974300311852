const getters = {
    getStats: (state) => {
        console.log("Accessing getStats getter:", state.stats);
        return state.stats;
    },
    getOtherData: (state) => {
        console.log("Accessing getOtherData getter:", state.otherData);
        return state.otherData;
    },
};

export default getters;
