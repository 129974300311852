import axios from "axios";
import {config as mainConfig } from "./config";

const defaultOptions = {
	baseURL: process.env.VUE_APP_API_URL,
	headers: {
		// "Access-Control-Allow-Origin": "*",
		// "Content-Type": "application/json",
	},
};

const MAX_REQUESTS_COUNT = 1;
const INTERVAL_MS = 10;
let PENDING_REQUESTS = 0;

const instance = axios.create(defaultOptions);

// Request interceptor
instance.interceptors.request.use(
	function(config) 
	{
		return new Promise((resolve) => {
			
			let interval = setInterval(() => 
			{
				if(PENDING_REQUESTS < MAX_REQUESTS_COUNT ) 
				{
					PENDING_REQUESTS++
					clearInterval(interval);

                    // Before request sent
                    // Inject in body
                    // config.data.application = "webMerchant";
                    // config.data.applicationVersion = "1.33";
                    // config.data.deviceId = "web";
                    // config.data.deviceDetail = "XYXYXYXYXYXYXYX";
                    // Inject in headers
                    // config.headers.apiId = 1;
                    // config.headers.apiSecret = "XYXYXYXYXYX";
                    
					// Before request sent
					// Inject in body
					if(config.data instanceof FormData){
						Object.entries(mainConfig.data().defaultBodyData).forEach(([key, val]) => {
							config.data.append(key,val)
						});
					}else{
						if(config.method == 'get'){
							config.params = {
								...config.params,
								...mainConfig.data().defaultBodyData,
							}
						}else if(config.method == 'post'){
							config.data = {
								...config.data,
								...mainConfig.data().defaultBodyData,
							}
						}
					}
					// Update accessToken in localStorage
					if (localStorage.getItem("accessToken")) {
						config.headers.accessToken = localStorage.getItem("accessToken");
					}

					resolve(config)
				}
			}, INTERVAL_MS)
		});
		
	},
	function(error) {
		// Do something with request error

		return Promise.reject(error);
	}
);

// Response interceptor
instance.interceptors.response.use(
	function(response) 
	{
		// Update accessToken in localStorage
		if (response.data.accessToken) {
			localStorage.setItem("accessToken", response.data.accessToken);
		}
		PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
		return response;
	},
	function(error) 
	{
		if (error?.response?.data?.accessToken) {

            localStorage.setItem("accessToken", error.response.data.accessToken);

        }
		PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1);
		return Promise.reject(error);
	}
);

export default instance;
