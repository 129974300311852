import { request } from "@/mixins/request";

const state = () => ({
  jobs: {
    data: null,
    pagination: null
  },
  jobsCreate: null,
  jobDetails: null
});

const getters = {
  getJobs(state) {
    return state.jobs;
  },
  getJobsCreate(state) {
    return state;
  },
  getJobDetail(state) {
    return state.jobDetails;
  }
};

const mutations = {
  setFetchJobs(state, payload) {
    state.jobs = payload.data;
    state.jobs.pagination = payload.pagination;
  },
  setFetchJobsCreate(state, payload) {
    state.jobsCreate = payload;
  },
  setJobDetail(state, payload) {
    state.jobDetails = payload;
  }
};

const actions = {
  setAuthenticated(context, payload) {
    context.commit("setAuthenticated", { payload });
  },
  async fetchJobs(context, payload) {
    const body = { ...context.getters.getFilters, ...payload };

    return await request.methods.makeRequest("POST", "/jobs", body, payload.options)
      .then(data => {
        context.commit("setFetchJobs", data);
        return data;
      });
  },
  async postEmail(context, payload) {
    // Send only the email payload, not the entire store context
    return await request.methods.makeRequest("POST", "/postEmail", payload) // send payload, not context
      .then(data => {
      return data;
      });
    },
  async fetchJobDetail(context, payload) {
    const body = { ...context.getters.getFilters, ...payload };

    return await request.methods.makeRequest("POST", "/job-detail", body, payload.options)
      .then(data => {
        context.commit("setJobDetail", data);
        return data;
      });
  },
  async fetchJobsCreate(context, payload) {
    return await request.methods.makeRequest("GET", "/jobdetails", payload.body, payload.options)
      .then(data => {
        context.commit("setFetchJobsCreate", data.data);
        return data;
      });
  },
  async addJob(context, payload) {
    return await request.methods.makeRequest("POST", "createjob", payload.body, payload.options)
      .then(data => {
        console.log(data);
        return data;
      });
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
