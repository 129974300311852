
<template>

    <main>

        <main class="antialiased">

            <div id="find-a-job">

                <div class="lg:max-w-5xl mx-auto mt-20 md:mt-32 md:px-6 xl:px-0 search-ui">

                    <JobStatusMenu />

                    <form @submit.prevent="addJob" class="space-y-8 ">

                        <div class="px-4 sm:px-6 md:px-0 mt-12">
                            <h2 class="text-2xl sm:text-3xl text-gray-900 font-bold">Job Information

                            </h2>
                        </div>
                        <p class="mt-1 text-sm text-gray-500">First, tell us about the position.
                            This information will be displayed
                            publicly so be careful what you share.</p>


                        <div class="space-y-8 ">
                            <div>

                                <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div class="sm:col-span-4">
                                        <label for="job_title" class="block text-sm font-medium text-gray-700">
                                            Job Title</label>
                                        <p class="mt-2 text-sm text-gray-500">Example: “Senior Designer”. Titles
                                            must describe one position.
                                        </p>
                                        <div class="mt-1 flex rounded-md shadow-sm">

                                            <input type="text"
                                                :class="'block w-full min-w-0 flex-1 rounded-none rounded-r-md focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ' + _vClass(_validate.form, 'job_title')"
                                                v-model="_validate.form.job_title.$model" id="job_title" />

                                        </div>
                                        <p v-if="_validate.form?.['job_title']?.$errors?.length > 0"
                                            class="peer-invalid:visible text-red-700 font-light">
                                            Please enter Job Title
                                        </p>
                                    </div>
                                    <div class="sm:col-span-3">
                                        <label for="job_category"
                                            class="block text-sm font-medium text-gray-700">Category</label>
                                        <div class="mt-1">
                                            <select id="job_category" v-model="_validate.form.job_category.$model"
                                            :class="'block w-full min-w-0 flex-1 rounded-none rounded-r-md focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ' + _vClass(_validate.form, 'job_category')">
                                                <option
                                                    v-for="(jobCategory, index) in this.$store.getters.getJobsCreate?.jobsCreate?.job_category"
                                                    :key="index" :value="jobCategory._id" option>{{ jobCategory.name
                                                    }}</option>
                                            </select>
                                        </div>
                                        <p v-if="_validate.form?.['job_category']?.$errors?.length > 0"
                                            class="peer-invalid:visible text-red-700 font-light">
                                            Please select a category
                                        </p>
                                    </div>

                                    <div class="sm:col-span-3">


                                        <label for="job_skill" class="block text-sm font-medium text-gray-700">Skill
                                            (If
                                            Applicable)</label>

                                        <div class="mt-1">

                                            <Multiselect mode="tags" v-model="form.job_skill" :multiple="true" track-by="id"
                                                :options="myOptions" />

                                        </div>

                                    </div>
                                    <div class="sm:col-span-4">
                                        <label for="link_or_email" class="block text-sm font-medium text-gray-700">
                                            Application Link or Email
                                        </label>
                                        <p class="mt-2 text-sm text-gray-500">Link to Application page or Email
                                            address.
                                        </p>
                                        <div class="mt-1 flex rounded-md shadow-sm">

                                            <input type="text" v-model="_validate.form.link_or_email.$model"
                                                id="link_or_email"
                                                :class="'block w-full min-w-0 flex-1 rounded-none rounded-r-md focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ' + _vClass(_validate.form, 'link_or_email')"/>
                                        </div>
                                        <p v-if="_validate.form?.['link_or_email']?.$errors?.length > 0"
                                            class="peer-invalid:visible text-red-700 font-light">
                                            Please enter Application Link or Email
                                        </p>
                                    </div>
                                    <div class="sm:col-span-2">
                                        <label class="text-base font-medium text-gray-900">Job Type</label>
                                        <p class="text-sm leading-5 text-gray-500">Select Your job type?</p>
                                        <div class="sm:col-span-3">
                                            <div class="mt-1">

                                                <select id="job_type" v-model="_validate.form.job_type.$model"
                                                :class="'block w-full min-w-0 flex-1 rounded-none rounded-r-md focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ' + _vClass(_validate.form, 'job_type')">
                                                    <option
                                                        v-for="(jobType, index) in this.$store.getters.getJobsCreate?.jobsCreate?.job_type"
                                                        :key="index" :value="jobType._id" option>{{ jobType.name
                                                        }}
                                                    </option>
                                                </select>
                                            </div>
                                            <p v-if="_validate.form?.['job_type']?.$errors?.length > 0"
                                                class="peer-invalid:visible text-red-700 font-light">
                                                Please Select Your job type
                                            </p>
                                        </div>
                                    </div>
                                    <div class="sm:col-span-2">
                                        <label class="text-base font-medium text-gray-900">Role Worldwide</label>
                                        <p class="text-sm leading-5 text-gray-500">Select Your Role Worldwide?</p>
                                        <div class="sm:col-span-3">
                                            <div class="mt-1">

                                                <select id="role_worldwide"
                                                    v-model="_validate.form.role_worldwide.$model"
                                                    :class="'block w-full min-w-0 flex-1 rounded-none rounded-r-md focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ' + _vClass(_validate.form, 'role_worldwide')">
                                                    <option value="1">Yes</option>
                                                    <option value="0">No</option>
                                                </select>
                                            </div>
                                            <p v-if="_validate.form?.['role_worldwide']?.$errors?.length > 0"
                                                class="peer-invalid:visible text-red-700 font-light">
                                                Please Select Your Role Worldwide
                                            </p>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-6">
                                        <label for="job_description" class="block text-sm font-medium text-gray-700">Job
                                            Description</label>
                                        <div class="mt-1">
                                            <vue-easymde v-model="form.job_description" ref="editorInstance" />
                                        </div>
                                    </div>




                                </div>
                            </div>

                            <div class="pt-8">
                                <div>
                                    <div class="px-4 sm:px-6 md:px-0 mt-6">
                                        <h2 class="text-2xl sm:text-3xl text-gray-900 font-bold">Company
                                            Information
                                        </h2>
                                    </div>
                                    <p class="mt-1 text-sm text-gray-500">Posted before? Just enter your email,
                                        all other info will be pulled in from your last position!.</p>
                                </div>
                                <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div class="sm:col-span-3">
                                        <label for="company_name" class="block text-sm font-medium text-gray-700">
                                            Company Name</label>
                                        <div class="mt-1">
                                            <input type="text" v-model="_validate.form.company_name.$model"
                                                id="company_name" autocomplete="given-name"
                                                :class="'block w-full min-w-0 flex-1 rounded-none rounded-r-md focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ' + _vClass(_validate.form, 'company_name')" />
                                        </div>
                                        <p v-if="_validate.form?.['company_name']?.$errors?.length > 0"
                                            class="peer-invalid:visible text-red-700 font-light">
                                            Please Enter Company Name
                                        </p>
                                    </div>

                                    <div class="sm:col-span-3">
                                        <label for="company_hq" class="block text-sm font-medium text-gray-700">
                                            Company HQ</label>
                                        <div class="mt-1">
                                            <input type="text" v-model="_validate.form.company_hq.$model"
                                                id="company_hq" autocomplete="family-name"
                                                :class="'block w-full min-w-0 flex-1 rounded-none rounded-r-md focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ' + _vClass(_validate.form, 'company_hq')"/>
                                        </div>
                                        <p v-if="_validate.form?.['company_hq']?.$errors?.length > 0"
                                            class="peer-invalid:visible text-red-700 font-light">
                                            Please Enter Company HQ
                                        </p>
                                    </div>
                                    <div class="sm:col-span-6">
                                        <label for="cover-photo" class="block text-sm font-medium text-gray-700">Company
                                            Logo</label>
                                        <p class="mt-1 text-sm text-gray-500">It’s highly recommended to use
                                            your Twitter or Facebook avatar. Optional — Your company logo will
                                            appear at the top of your listing and live profile.</p>
                                        <div
                                            class="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                                            <div class="space-y-1 text-center">
                                                <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor"
                                                    fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                    <path
                                                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                        stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round" />
                                                </svg>
                                                <div class="flex text-sm text-gray-600">
                                                    <label for="file-upload"
                                                        class="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500">
                                                        <span>Upload a file</span>
                                                        <input id="file-upload" @change="handleFileUpload($event)"
                                                            type="file" class="sr-only" />
                                                    </label>
                                                    <p class="pl-1">or drag and drop</p>
                                                </div>
                                                <p class="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="sm:col-span-4">
                                        <label for="company_website_url"
                                            class="block text-sm font-medium text-gray-700">Company's Website
                                            URL</label>
                                        <p class="mt-1 text-sm text-gray-500">Example: https://mybusiness.com/
                                        </p>

                                        <div class="mt-1">
                                            <input id="company_website_url"
                                                v-model="_validate.form.company_website_url.$model" type="text"
                                                :class="'block w-full min-w-0 flex-1 rounded-none rounded-r-md focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ' + _vClass(_validate.form, 'company_website_url')" />
                                        </div>
                                        <p v-if="_validate.form?.['company_website_url']?.$errors?.length > 0"
                                            class="peer-invalid:visible text-red-700 font-light">
                                            Please Enter Company Website
                                            URL
                                        </p>
                                    </div>

                                    <div class="sm:col-span-4">
                                        <label for="email" class="block text-sm font-medium text-gray-700">Company's
                                            Email</label>
                                        <p class="mt-1 text-sm text-gray-500">We’ll send your receipt and
                                            confirmation email here</p>

                                        <div class="mt-1">
                                            <input id="email" v-model="_validate.form.email.$model" type="email"
                                            :class="'block w-full min-w-0 flex-1 rounded-none rounded-r-md focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ' + _vClass(_validate.form, 'email')" />
                                        </div>
                                        <p v-if="_validate.form?.['email']?.$errors?.length > 0"
                                            class="peer-invalid:visible text-red-700 font-light">
                                            Please Enter Company Email
                                        </p>
                                    </div>
                                    <div class="sm:col-span-6">
                                        <label for="company_description"
                                            class="block text-sm font-medium text-gray-700">
                                            Company Description</label>
                                        <div class="mt-1">
                                            <vue-easymde v-model="form.company_description" ref="editorInstance" />
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>

                        <div class="pt-5">
                            <div class="flex justify-end">
                                <button type="button"
                                    class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Cancel</button>
                                <button type="submit"
                                    class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </main>

    </main>
    <input id="tom-select-it" />

</template>

<script>

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import Multiselect from '@vueform/multiselect'

import axios from "axios";
import JobStatusMenu from "./JobStatusMenu.vue";
import { EditorInstance } from 'vue3-easymde';

import { ChevronRightIcon } from '@heroicons/vue/20/solid'
export default {


    setup() {

        return { _validate: useVuelidate() };
    },

    components: {
        Multiselect,
        JobStatusMenu
    },

    data() {


        return {
            form: {
                company_logo_link: null,
                job_category: null,
                job_skill: [],
                job_type: null,
                job_title: null,
                role_worldwide: null,
                link_or_email: null,
                job_description: null,
                company_name: null,
                company_hq: null,
                company_website_url: null,
                email: null,
                company_description: null,
            },

            message: null,
            myValue: '',
            myOptions: [], // or [{id: key, text: value}, {id: key, text: value}]
            value: [],
            options: [
                'Batman',
                'Robin',
                'Joker',
            ],
            selectedOptions: ['I'],
            options: ['I', 'Love', 'Vue'],
            books: [
                { title: "Old Man's War" },
                { title: "The Lock Artist" },
                { title: "HTML5" },
                { title: "Right Ho Jeeves" },
                { title: "The Code of the Wooster" },
                { title: "Thank You Jeeves" }
            ],
            action: "fetchJobsCreate",
        };
    },

    watch: {
        "$store.getters.getJobsCreate.jobsCreate": {
            handler: function (data) {

                let app = this;
                data?.job_skill?.filter(function (item) {
                    const singel = { value: item._id, label: item.name };
                    app.myOptions.push(singel);
                });
            },
        }
    },

    methods: {
        handleFileUpload(event) {
            this.form.company_logo_link = event.target.files[0];
        },

        async getFilteredData() {
            this.$store.dispatch(this.action, {
                body: {},
                options: {
                    loader: true,
                    tableSkeleton: true,
                },
            });
        },

        addJob() {
            this._validate.$validate();
            console.log("asdf", this._validate.$error)

            if (!this._validate.$error) {

                let formData = new FormData();
                formData.append(
                    'company_logo_link', this.form.company_logo_link,

                );
                formData.append(
                    'job_category', this.form.job_category,

                );
                formData.append(
                    'job_skill', JSON.stringify(this.form.job_skill),

                );
                formData.append(
                    'job_type', this.form.job_type,

                );
                formData.append(
                    'job_title', this.form.job_title,

                );
                formData.append(
                    'role_worldwide', this.form.role_worldwide,

                );
                formData.append(
                    'link_or_email', this.form.link_or_email,

                );
                formData.append(
                    'job_description', this.form.job_description,

                );
                formData.append(
                    'company_name', this.form.company_name,

                );
                formData.append(
                    'company_hq', this.form.company_hq,

                );
                formData.append(
                    'company_website_url', this.form.company_website_url,

                );
                formData.append(
                    'email', this.form.email,

                );
                formData.append(
                    'company_description', this.form.company_description,

                );

                axios.post('/createjob',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then((res) => {
                    console.log(res.data.data._id);
                    const job_id =  res.data.data._id;
                    const path_route = '/job-preview/'+job_id+'';
                    setTimeout( () => this.$router.push({ path: path_route}), 5000);
                    this.setToast({
                        type: "success",
                        message: "You have successfully Submited 1st step of Job application!",
                        position: "right_btm"
                    })

                })
                    .catch(function () {
                        this.setToast({
                            type: "error",
                            message: "There are issues in submitting form.!",
                            position: "right_btm"
                        })
                    });
            }
        }
    },
    created() {


        if (this.$store.getters.getJobsCreate?.job_skill) {
            let app = this;
            this.$store.getters.getJobsCreate.job_skill.map(function (value, key) {
                const singel = { value: value._id, label: value.name };

                app.myOptions.push(singel);
            });
        } else {
            this.getFilteredData();
        }
    },
    computed: {
        message: {
            get() {
                return this.$store.getters.getJobsCreate.job_skill
            },

        }
    },

    validations() {
        return {
            form: {
                job_category: {
                    required,
                },
                job_type: {
                    required,
                },
                job_title: {
                    required,
                },
                role_worldwide: {
                    required,
                },
                link_or_email: {
                    required,
                },
                company_name: {
                    required,
                },
                company_hq: {
                    required,
                },
                email: {
                    required,
                },
                company_website_url: {
                    required,
                },
            },
        };

    },
};
</script>


<style src="@vueform/multiselect/themes/default.css">

</style>
