
<template>

    <main>
        <div class="bg-gray-900 pt-10 sm:pt-16 lg:overflow-hidden lg:pt-8 lg:pb-14">
            <div class="mx-auto max-w-7xl lg:px-8">
                <div class="lg:grid lg:grid-cols-2 lg:gap-8">
                    <div
                        class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left">
                        <div class="lg:py-24">
                            <a href="#"
                                class="inline-flex items-center rounded-full bg-black p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base">
                                <span
                                    class="rounded-full bg-gradient-to-r from-teal-500 to-cyan-600 px-3 py-0.5 text-sm font-semibold leading-5 text-white">Seacrch
                                    a job</span>
                                <span class="ml-4 text-sm">Click here</span>
                                <ChevronRightIcon class="ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
                            </a>
                            <h1
                                class="mt-4 text-4xl font-bold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                                <span class="block">The Leading Job Board</span>
                                <span
                                    class="block bg-gradient-to-r from-teal-200 to-cyan-400 bg-clip-text pb-3 text-transparent sm:pb-5">For
                                    Blockchain And Cryptocurrency Jobs</span>
                            </h1>
                            <p class="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">Anim aute id
                                magna aliqua ad ad non deserunt sunt. Qui irure qui Lorem cupidatat commodo.
                                Elit sunt amet fugiat veniam occaecat fugiat.</p>
                            <div class="mt-10 sm:mt-12">
                                <form action="#" class="sm:mx-auto sm:max-w-xl lg:mx-0">
                                    <div class="sm:flex">
                                        <div class="min-w-0 flex-1">
                                            <label for="email" class="sr-only">Email address</label>
                                            <input id="email" type="email" placeholder="Enter your email"
                                                class="block w-full rounded-md border-0 px-4 py-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900" />
                                        </div>
                                        <div class="mt-3 sm:mt-0 sm:ml-3">
                                            <button type="submit"
                                                class="block w-full rounded-md bg-gradient-to-r from-teal-500 to-cyan-600 py-3 px-4 font-medium text-white shadow hover:from-teal-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900">Subscribe</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="mt-12 -mb-16 sm:-mb-48 lg:relative lg:m-0">
                        <div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                            <!-- Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ -->
                            <img class="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                                src="https://tailwindui.com/img/component-images/cloud-illustration-teal-cyan.svg"
                                alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>





        <main class="antialiased">

            <div id="find-a-job">


                <div class="lg:max-w-5xl mx-auto mt-20 md:mt-32 md:px-6 xl:px-0 search-ui">
                    <JobStatusMenu />

                    <div class="px-4 sm:px-6 md:px-0 mt-12">
                        <h2 class="text-2xl sm:text-3xl text-gray-900 font-bold">Job Detail Page Preview
                        </h2>
                    </div>
                    <div class="lg:max-w-6xl mx-auto px-4 sm:px-6 xl:px-0">
                        <div class="pt-2 md:pt-2">
                            <h1
                                class="text-4xl md:text-6xl lg:text-7xl text-gray-900 font-extrabold md:leading-tighter lg:leading-tighter tracking-tight break-words md:break-normal mt-6 md:mt-7">
                                {{ this.$store.getters.getJobDetail.data[0].job_title }}</h1>
                            <div class="flex items-center mt-3 md:mt-4">
                                <div class="mr-3"><img
                                        class="bg-white border border-gray-100 h-9 md:h-12 w-9 md:w-12 rounded-full lazyautosizes lazyloaded"
                                        :src="baseURL + '/' + this.$store.getters.getJobDetail.data[0].company_logo_link"
                                        height="400" width="400" alt="Octav logo" sizes="48px"></div>
                                <h2
                                    class="text-xl md:text-3xl text-gray-900 font-medium leading-none sm:leading-none md:leading-none break-words md:break-normal">
                                    <a class="hover:text-purple transition-colors" href="#">{{
                                            this.$store.getters.getJobDetail.data[0].company_name
                                    }}</a>
                                </h2>
                            </div>
                        </div>
                        <div class="pb-20 pt-10 md:py-24 lg:py-28">
                            <div class="lg:max-w-5xl grid lg:grid-cols-4" style="grid-template-rows:auto 1fr">
                                <div class="lg:col-span-3 row-start-2 lg:row-span-3 mt-20 lg:mt-0">
                                    <div class="prose">
                                        <h2 id="about-octav">About {{
                                                this.$store.getters.getJobDetail.data[0].company_name
                                        }}
                                        </h2>
                                        <p>{{ this.$store.getters.getJobDetail.data[0].company_description }}</p>

                                        <h2 id="what-you-will-work-on">Job Description </h2>
                                        <p>{{ this.$store.getters.getJobDetail.data[0].job_description }}</p>
                                    </div>

                                </div>
                                <div class="lg:col-start-4 row-start-1 lg:row-start-1">
                                    <div>
                                        <h3 class="text-xs text-gray-500 font-medium tracking-wide uppercase">Location
                                        </h3>
                                        <ul class="text-gray-600 leading-relaxed mt-1">
                                            <li class="inline"><span class="hover:text-purple transition-colors">{{
                                                    this.$store.getters.getJobDetail.data[0].company_hq
                                            }}</span><span aria-hidden="true">,&nbsp;</span></li>
                                            <li class="inline"><span class="hover:text-purple transition-colors">{{
                                                    this.$store.getters.getJobDetail.data[0].job_type.name
                                            }}</span></li>
                                        </ul>
                                    </div>
                                    <div class="mt-6">
                                        <h3 class="text-xs text-gray-500 font-medium tracking-wide uppercase">Job type
                                        </h3>
                                        <ul class="text-gray-600 leading-relaxed mt-1">
                                            <li class="inline-block"><span
                                                    class="hover:text-purple transition-colors">{{
                                                            this.$store.getters.getJobDetail.data[0].job_type.name
                                                    }}</span></li>
                                        </ul>
                                    </div>
                                    <!-- <div class="mt-6">
                                <h3 class="text-xs text-gray-500 font-medium tracking-wide uppercase">Compensation</h3>
                                <div class="text-gray-600 mt-1"><span>C$150K – C$200K</span>
                                    <span class="block mt-2"><a class="hover:text-purple transition-colors"
                                            href="/jobs-that-pay-in-crypto/">The option of getting paid in digital
                                            currency</a></span>
                                </div>
                            </div> -->
                                    <div class="mt-6">
                                        <h3 class="text-xs text-gray-500 font-medium tracking-wide uppercase">Skills
                                        </h3>

                                        <ul class="text-gray-600 leading-relaxed mt-1">

                                            <li v-for="(job_skill, index) in this.$store.getters.getJobDetail.data[0]?.job_skill"
                                                :key="index" :value="job_skill.id" class="inline"><span
                                                    class="hover:text-purple transition-colors"> {{ job_skill.name }}
                                                    ,&nbsp;</span>
                                            </li>

                                        </ul>
                                    </div>
                                    <div class="mt-6">
                                        <h3 class="text-xs text-gray-500 font-medium tracking-wide uppercase">Share</h3>
                                        <ul class="text-gray-600 leading-relaxed mt-2">
                                            <li class="inline-block mr-3 md:mr-2"><a
                                                    class="text-gray-500 hover:text-purple transition-colors" href="#"
                                                    target="_blank" rel="noopener noreferrer"><svg
                                                        class="h-5 w-5 rounded-md lazyloaded" aria-label="Facebook"
                                                        role="img" viewBox="0 0 512 512">
                                                        <rect fill="currentcolor" height="512" width="512" rx="15%">
                                                        </rect>
                                                        <path fill="#fff"
                                                            d="M355.6 330l11.4-74h-71v-48c0-20.2 9.9-40 41.7-40H370v-63s-29.3-5-57.3-5c-58.5.0-96.7 35.4-96.7 99.6V256h-65v74h65v182h80V330h59.6z">
                                                        </path>
                                                    </svg><span class="sr-only">Share on Facebook</span></a></li>
                                            <li class="inline-block mr-3 md:mr-2"><a
                                                    class="text-gray-500 hover:text-purple transition-colors" href="#"
                                                    target="_blank" rel="noopener noreferrer"><svg
                                                        class="h-5 w-5 rounded-md lazyloaded" aria-label="Twitter"
                                                        role="img" viewBox="0 0 512 512">
                                                        <rect fill="currentcolor" height="512" width="512" rx="15%">
                                                        </rect>
                                                        <path fill="#fff"
                                                            d="M437 152a72 72 0 01-40 12 72 72 0 0032-40 72 72 0 01-45 17 72 72 0 00-122 65 2e2 2e2.0 01-145-74 72 72 0 0022 94 72 72 0 01-32-7 72 72 0 0056 69 72 72 0 01-32 1 72 72 0 0067 50A2e2 2e2.0 0193 368a2e2 2e2.0 00309-179 2e2 2e2.0 0035-37">
                                                        </path>
                                                    </svg><span class="sr-only">Share on Twitter</span></a></li>
                                            <li class="inline-block mr-3 md:mr-2"><a
                                                    class="text-gray-500 hover:text-purple transition-colors" href="#"
                                                    target="_blank" rel="noopener noreferrer"><svg
                                                        class="h-5 w-5 rounded-md" fill="#fff" aria-label="LinkedIn"
                                                        role="img" viewBox="0 0 512 512">
                                                        <rect fill="currentcolor" height="512" width="512" rx="15%">
                                                        </rect>
                                                        <circle cx="142" cy="138" r="37"></circle>
                                                        <path stroke="#fff" stroke-width="66"
                                                            d="M244 194v198M142 194v198">
                                                        </path>
                                                        <path
                                                            d="M276 282c0-20 13-40 36-40 24 0 33 18 33 45v105h66V279c0-61-32-89-76-89-34 0-51 19-59 32">
                                                        </path>
                                                    </svg><span class="sr-only">Share on LinkedIn</span></a></li>
                                            <li class="inline-block"><a
                                                    class="text-gray-500 hover:text-purple transition-colors" href="#"
                                                    target="_blank" rel="noopener noreferrer"><svg
                                                        class="h-5 w-5 rounded-md" aria-label="Email" role="img"
                                                        viewBox="0 0 512 512">
                                                        <rect fill="currentcolor" height="512" width="512" rx="15%">
                                                        </rect>
                                                        <rect fill="#fff" height="256" width="356" x="78" y="128"
                                                            rx="8%">
                                                        </rect>
                                                        <path fill="none" stroke="teal" stroke-width="20"
                                                            d="M434 128 269 292c-7 8-19 8-26 0L78 128m0 256 129-128m227 128L305 256">
                                                        </path>
                                                    </svg><span class="sr-only">Share by Email</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="lg:col-start-4 row-start-3 lg:row-start-2">
                                    <div class="mt-14 sm:mt-12">
                                        <h3 class="text-xs text-gray-500 font-medium tracking-wide uppercase">About {{
                                                this.$store.getters.getJobDetail.data[0].company_name
                                        }}</h3>
                                        <div class="text-gray-600 mt-1">
                                            <p><a class="hover:text-purple transition-colors" href="#">Company
                                                    profile</a></p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="hits" class="mt-0">
                        <div class="px-4 sm:px-6 md:px-0 mt-12">
                            <h2 class="text-2xl sm:text-3xl text-gray-900 font-bold">Job Front Page Preview
                            </h2>
                        </div>
                        <div>
                            <div class="ais-Hits">
                                <ol class="ais-Hits-list">

                                    <li class="ais-Hits-item">
                                        <div class="grid text-sm text-gray-600 bg-white border-t border-gray-200 p-4 sm:p-6"
                                            style="grid-template-columns: auto minmax(0, 1fr)">

                                            <img class="h-8 sm:h-10 md:h-11 w-8 sm:w-10 md:w-11 bg-white border border-gray-100 lazyload sm:mr-4 rounded-full"
                                                :src="baseURL + '/' + this.$store.getters.getJobDetail.data[0].company_logo_link"
                                                data-sizes="auto" height="44" width="44" alt="Upshot logo">




                                            <div class="col-start-1 sm:col-start-2 row-start-2 sm:row-start-1">
                                                <h2
                                                    class="text-lg sm:text-xl md:text-2xl text-gray-800 font-bold mt-2 sm:mt-0">
                                                    <a class="hover:text-purple transition-colors"
                                                        :href="'/job-detail/' + this.$store.getters.getJobDetail.data[0]._id">
                                                        {{ this.$store.getters.getJobDetail.data[0].job_title }} </a>
                                                </h2>


                                                <h3 class="text-base md:text-lg text-gray-800"> <a
                                                        class="hover:text-purple transition-colors"
                                                        :href="this.$store.getters.getJobDetail.data[0].company_website_url">
                                                        {{ this.$store.getters.getJobDetail.data[0].company_name }} </a>
                                                </h3>

                                                <h5
                                                    v-if="this.$store.getters.getJobDetail.data[0].job_description.length < 400">
                                                    {{ this.$store.getters.getJobDetail.data[0].job_description }}
                                                </h5>
                                                <h5 v-else>{{
                                                        this.$store.getters.getJobDetail.data[0].job_description.substring(0,
                                                            400) + "..."
                                                }}</h5>

                                            </div>
                                            <div class="col-start-1 sm:col-start-2 col-end-2 sm:col-end-4">
                                                <div class="flex flex-row flex-wrap mt-3 space-x-1 sm:space-x-2">
                                                    <ul class="leading-relaxed sm:leading-normal">
                                                        <li class="inline">
                                                            <h4 class="inline"><a
                                                                    class="hover:text-purple transition-colors"
                                                                    href="#">{{
                                                                            this.$store.getters.getJobDetail.data[0].company_hq
                                                                    }}</a> </h4>
                                                        </li>
                                                    </ul> <span aria-hidden="true">·</span>
                                                    <h4 class="leading-relaxed sm:leading-normal"> <a
                                                            class="hover:text-purple transition-colors" href="/design/">
                                                            {{
                                                                    this.$store.getters.getJobDetail.data[0].job_category.name
                                                            }} </a> </h4> <span aria-hidden="true">·</span>
                                                    <ul class="leading-relaxed sm:leading-normal">
                                                        <li class="inline-block">
                                                            <h4 class="inline"><a
                                                                    class="hover:text-purple transition-colors"
                                                                    href="/full-time/">Full-Time</a></h4>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <ul
                                                    class="flex flex-wrap -mb-1 sm:-mb-0.5 mt-1 md:mt-2 -mx-1 sm:-mx-0.5">
                                                    <li v-for="(job_skill, index) in this.$store.getters.getJobDetail.data[0].job_skill"
                                                        :key="index" :value="job_skill.id"
                                                        class="border border-gray-300 m-1 sm:m-0.5 rounded-md group hover:border-purple transition-colors">
                                                        <a class="block group-hover:text-purple p-1" href="#">
                                                            {{ job_skill.name }} </a>
                                                    </li>


                                                </ul>
                                            </div>
                                            <div
                                                class="col-start-3 row-start-1 flex items-baseline justify-end self-center sm:self-auto">
                                                <div class="inline-block leading-loose"> <time
                                                        datetime="2022-07-13T08:00:00"></time> <span> {{
        vpDate(this.$store.getters.getJobDetail.data[0].createdAt)
                                                        }} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                </ol>

                                <div class="pt-5">
                                    <div class="flex justify-end">
                                        <button type="button"
                                            class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Cancel</button>
                                        <button type="submit"
                                            class="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Move To Payment</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </main>




    </main>
    <input id="tom-select-it" />

</template>

<script>

import JobStatusMenu from "./JobStatusMenu.vue";
import { ChevronRightIcon } from '@heroicons/vue/20/solid'


export default {
    components: {
        JobStatusMenu
    },
    data() {
        return {
            action: "fetchJobDetail",
            baseURL: process.env.VUE_APP_API_URL,
        };
    },
    methods: {

        async getFilteredData() {
            var jobData = {
                _id: this.$route.params.id
            }

            await this.$store.dispatch(this.action, {
                body: { ...jobData },
                options: {
                    loader: true,
                    tableSkeleton: true,
                },
            });
        },
        async fetchJobsCreate() {
            await this.$store.dispatch('fetchJobsCreate', {
                body: {},
            });
        },

    },
    created() {
        this.resetFilters();
        this.getFilteredData();
        this.fetchJobsCreate();

    },
};
</script>


<style src="@vueform/multiselect/themes/default.css">

</style>
