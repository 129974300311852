<template>
  <header class="relative isolate z-10 bg-white border-b border-gray-200">
    <nav class="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
      <div class="flex lg:flex-1">
        <a href="#" class="-m-1.5 p-1.5 pl-6">
          <span class="sr-only">Your Company</span>
          <img class="h-10 w-auto" src="/assets/images/services/Dev2.png" alt="" />
        </a>
      </div>
      <div class="flex lg:hidden">
        <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          @click="mobileMenuOpen = true">
          <span class="sr-only">Open main menu</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <PopoverGroup class="lg:flex lg:gap-x-12">
        <!-- Always show Services on desktop -->
        <Popover >
          <PopoverButton @click="toggleServicesPopover"
            class="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 cursor-pointer">
            Services
            <ChevronDownIcon class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
          </PopoverButton>

          <transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 -translate-y-1"
            enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150"
            leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 -translate-y-1">
            <PopoverPanel v-if="servicesPopoverOpen"
              class="absolute inset-x-0 top-0 -z-10 bg-white pt-14 shadow-lg ring-1 ring-gray-900/5">

              <div class="mx-auto mt-16 max-w-5xl sm:mt-20 lg:mt-24 lg:max-w-none">
                <dl class="grid max-w-4xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                  <div v-for="item in products" :key="item.name"
                    class="flex flex-col bg-white shadow-lg rounded-lg p-6 transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-2xl">
                    <dt class="text-base font-semibold leading-7 text-cyan-600">
                      <div class="flex mb-5">
                        <img class="h-12 w-auto" :src="item.href" alt="" />
                      </div>
                      <a :href="item.url" class="mt-6 block font-semibold text-gray-900">
                        {{ item.name }}
                        <span class="absolute inset-0" />
                      </a>
                    </dt>
                    <dd class="mt-2 flex flex-auto flex-col text-base leading-7 text-gray-900">
                      <p class="flex-auto">{{ item.description }}</p>
                    </dd>
                  </div>
                </dl>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>

        <!-- Show Pricing only on mobile -->
        <template v-if="isMobile">
          </template>

        <!-- Always show Services and Pricing on desktop -->
        <template v-else>
          <a v-if="navigation[0]" :href="navigation[0].href" class="text-sm font-semibold leading-6 text-gray-900">{{ navigation[0].name }}</a>
          <a v-if="navigation[1]" :href="navigation[1].href" class="text-sm font-semibold leading-6 text-gray-900">{{ navigation[1].name }}</a>
          <a v-if="navigation[2]" :href="navigation[2].href" class="text-sm font-semibold leading-6 text-gray-900">{{ navigation[2].name }}</a>
        </template>
      </PopoverGroup>

      <div class="lg:flex lg:flex-1 lg:justify-end">
        <a href="/post-a-job"
           class="inline-flex items-center rounded-full bg-black p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base">
            <span class="rounded-full bg-gradient-to-r from-teal-500 to-cyan-600 px-3 py-0.5 text-sm font-semibold leading-5 text-white">
                Post A Job
            </span>
        </a>
      </div>
    </nav>
  </header>
</template>


<script>
import { ref, computed } from 'vue'
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from '@headlessui/vue'
import {
  Bars3Icon,
} from '@heroicons/vue/24/outline'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'

export default {
  data() {
    return {
      navigation: [
        { name: 'About', href: '/about-us' },
        { name: 'Pricing', href: '/pricing' },
        { name: 'Help Full Resources', href: '/blogs' },
        { name: '', href: '#' },
      ],
      products: [
        {
          name: 'AI-Powered HR Reports',
          description: 'Our AI-powered solution simplifies human resource management.',
          href: '/assets/images/services/human-resouces-devintegrity.png',
          url: '/hrandmarketting'
        },
        {
          name: 'Human Resouces Management',
          description: 'With our HR services, Reclaim the power of your network to hire and grow.',
          href: '/assets/images/services/Human_Resources.png',
          url: '/hrandmarketting'
        },
        {
          name: 'Mobile App Development',
          description: 'Reliable mobile app development partner who can dive deep into your business ecosystem.',
          href: '/assets/images/services/booking-ma.jpg',
          url: '/mobileAppDevelopment'
        },
        {
          name: 'Web Application Development',
          description: 'Keep full control of your business processes as you grow your company through well-designed, secured & bespoke web applications.',
          href: '/assets/images/services/Web_Application_development.png',
          url: '/webDevelopment'
        },
        {
          name: 'App Maintenance & Support',
          description: 'We maintain, support and sustain critical applications to keep your investment secure & allow your application to move speed your business.',
          href: '/assets/images/services/App_Maintenance_Support.png',
          url: '/appMaintinessAndSupport'
        },
        {
          name: 'E-Commerce Development',
          description: 'Creating strategic digital shopping solutions that deliver tangible business results, boosts your online store engagement and increases brand exposure.',
          href: '/assets/images/services/ECommerce_Development.png',
          url: '/eCommerce'
        },
        {
          name: 'CMS Development',
          description: 'Get a result-driven website that captures your brand, improves your conversion rates, and maximizes your revenue to move your business forward.',
          href: '/assets/images/services/CMS_Development.png',
          url: '/webDevelopment'
        },
        {
          name: 'API Integration',
          description: 'Streamlining the business processes for optimized results with seamless third-party API integration services.',
          href: '/assets/images/services/Api_Integration.png',
          url: '/webDevelopment'
        },
        {
          name: 'DevOps',
          description: 'We design impactful and fully tested experiences for your digital products. Our team can help you lessen releases, cut down costs, and keep ahead of the competition.',
          href: '/assets/images/services/Dev_Ops.png',
          url: '/webDevelopment'
        },
      ],
      servicesPopoverOpen: ref(false),
      mobileMenuOpen: ref(false),
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768; // Adjust the width as needed
    }
  },
  methods: {
    toggleServicesPopover() {
      this.servicesPopoverOpen = !this.servicesPopoverOpen;
    },
    async importImage(path) {
      const { default: src } = await import(`${path}`);
      return src;
    },
  },

  async mounted() {
    await Promise.all(this.products.map(async (product) => {
      product.src = await this.importImage(product.href);
    }));
  }
}
</script>
