import CreateJobPost from "../views/front/job/Create";
import JobPreview from "../views/front/job/Preview";
import singleCategoryPreview from "../views/front/jobCategory/index";
import Pricing from "../views/front/pricing";
import Blogs from "../views/front/blogs";
import customWebApplicationDevelopment from "../views/front/blogdetail/customWebApplicationDevelopment";
import customWebDevelopment from "../views/front/blogdetail/customWebDevelopment";
import cloudMigrationProcess from "../views/front/blogdetail/cloudMigrationProcess";
import wooCommerce from "../views/front/blogdetail/wooCommerce";
import masterWebDevelopmentService from "../views/front/blogdetail/masterWebDevelopmentService";
import healthCareIndustry from "../views/front/blogdetail/healthCareIndustry";
import contentVelocity from "../views/front/blogdetail/contentVelocity";
import chatGpt from "../views/front/blogdetail/chatGpt";
import businessNeedsCMS from "../views/front/blogdetail/businessNeedsCMS";
import webDevelopment from "../views/front/services/webDevelopment";
import mobileAppDevelopment from "../views/front/services/mobileAppDevelopment";
import appMaintinessAndSupport from "../views/front/services/appMaintinessAndSupport";
import hrandmarketting from "../views/front/services/hrandmarketting";
import eCommerse from "../views/front/services/eCommerce";
import CMSDevelopmentServices from "../views/front/services/webDevelopment";
import Blogdetail from "../views/front/blogdetail";
import aboutUs from "../views/front/aboutus";
import JobDetails from "../views/front/job/Detail";
import PrivacyPolicy from "../views/front/privacy/privacyPolicy";
import TermsOfService from "../views/front/privacy/termsOfService";
import Gdpr from "../views/front/privacy/gdpr";

export default [

    {
        name: 'PostJob',
        path: '/post-a-job',
        component: CreateJobPost,
        meta: {
            title: 'Job Post view'
        }
    },
    // Blogs
    {
        name: 'blogs',
        path: '/blogs',
        component: Blogs,
        meta: {
            title: 'Blogs'
        }
    },
    {
        name: 'JobPreview',
        path: '/job-preview',
        component: JobPreview,
        meta: {
            title: 'Job preview'
        }
    },
    // Partners
    {
        name: 'aboutUs',
        path: '/about-us',
        component: aboutUs,
        meta: {
            title: 'About Us'
        }
    },
    // Pricing
    {
        name: 'Pricing',
        path: '/pricing',
        component: Pricing,
        meta: {
            title: 'Pricing'
        }
    },
    // blog detail
    {
        name: 'Detail',
        path: '/blog-detail',
        component: Blogdetail,
        meta: {
            title: 'Detail'
        }
    },
    // privacy policy
    {
        name: 'PrivacyPolicy',
        path: '/privacy-policy',
        component: PrivacyPolicy,
        meta: {
            title: 'Privacy Policy Dev Integrity'
        }
    },
    // Terms Of Service
    {
        name: 'TermsOfService',
        path: '/terms-of-Service',
        component: TermsOfService,
        meta: {
            title: 'Terms Of Service Dev Integrity'
        }
    },
    // gdpr
    {
        name: 'gdpr',
        path: '/gdpr',
        component: Gdpr,
        meta: {
            title: 'General Data Protection Regulation Dev Integrity'
        }
    },
    {
        name: 'customWebDevelopment',
        path: '/blog-detail/customWebDevelopment',
        component: customWebDevelopment,
        meta: {
            title: 'Custom Web Development'
        }
    },
    {
        name: 'cloudMigrationProcess',
        path: '/blog-detail/cloudMigrationProcess',
        component: cloudMigrationProcess,
        meta: {
            title: '3 Steps For A Successful Cloud Migration Process'
        }
    },
    {
        name: 'wooCommerce',
        path: '/blog-detail/wooCommerce',
        component: wooCommerce,
        meta: {
            title: 'Benefit from Custom Web Development'
        }
    },
    {
        name: 'customWebApplicationDevelopment',
        path: '/blog-detail/customWebApplicationDevelopment',
        component: customWebApplicationDevelopment,
        meta: {
            title: '10 Compelling Reasons For Custom Web Application Development'
        }
    },
    {
        name: 'masterWebDevelopmentService',
        path: '/blog-detail/masterWebDevelopmentService',
        component: masterWebDevelopmentService,
        meta: {
            title: '3 Tips to Market a Master Web Development Service'
        }
    },
    {
        name: 'healthCareIndustry',
        path: '/blog-detail/healthCareIndustry',
        component: healthCareIndustry,
        meta: {
            title: 'Benefits of Mobile App Development in HealthCare Industry'
        }
    },
    {
        name: 'contentVelocity',
        path: '/blog-detail/contentVelocity',
        component: contentVelocity,
        meta: {
            title: 'Digital Evolution & Content Velocity'
        }
    },
    {
        name: 'chatGpt',
        path: '/blog-detail/chatGpt',
        component: chatGpt,
        meta: {
            title: 'How Chat GPT is Revolutionizing Various Industries'
        }
    },
    {
        name: 'businessNeedsCMS',
        path: '/blog-detail/businessNeedsCMS',
        component: businessNeedsCMS,
        meta: {
            title: 'How Chat GPT is Revolutionizing Various Industries'
        }
    },
    {
        name: 'webDevelopment',
        path: '/webDevelopment',
        component: webDevelopment,
        meta: {
            title: 'How Chat GPT is Revolutionizing Various Industries'
        }
    },
    {
        name: 'mobileAppDevelopment',
        path: '/mobileAppDevelopment',
        component: mobileAppDevelopment,
        meta: {
            title: 'How Chat GPT is Revolutionizing Various Industries'
        }
    },
    {
        name: 'appMaintinessAndSupport',
        path: '/appMaintinessAndSupport',
        component: appMaintinessAndSupport,
        meta: {
            title: 'How Chat GPT is Revolutionizing Various Industries'
        }
    },
    {
        name: 'hrandmarketting',
        path: '/hrandmarketting',
        component: hrandmarketting,
        meta: {
            title: 'How Chat GPT is Revolutionizing Various Industries'
        }
    },
    {
        name: 'CMSDevelopmentServices',
        path: '/blog-detail/CMSDevelopmentServices',
        component: CMSDevelopmentServices,
        meta: {
            title: 'How Chat GPT is Revolutionizing Various Industries'
        }
    },
    {
        name: 'eCommerse',
        path: '/eCommerce',
        component: eCommerse,
        meta: {
            title: 'How Chat GPT is Revolutionizing Various Industries'
        }
    },
    
    {
        name: 'singleCategoryPreview',
        path: '/category/:id',
        component: singleCategoryPreview,
        meta: {
            title: 'Category Jobs preview'
        }
    },
    {
        name: 'JobPreview',
        path: '/job-preview/:id',
        component: JobPreview,
        meta: {
            title: 'Job preview'
        }
    },
    {
        name: "jobDetails",
        path: "/job-detail/:id/",
        component: JobDetails,
        meta: {
            title: 'Job Details'
        }
    },
]
