import { createStore } from "vuex";
import { globalHelpers } from "@/mixins/globalHelpers";
import dashboard from "./modules/dashboard";
import notification from "./modules/notification/index1.js";
import jobModule from "./modules/jobs/index1.js";
import general from "./modules/general/index1.js";
import Filters from "./modules/Filters/index1.js";

console.log("Initializing Vuex store...");

const store = createStore({
  modules: {
     dashboard,
    notification: notification,
    general: general,
    jobs: jobModule,
    Filters,
  },
  mixins: {
    globalHelpers,
  },
});

console.log("Vuex store initialized:", store);

export default store;
