import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import router from "./router";
import store from "./store";
import { globalHelpers } from "@/mixins/globalHelpers";
import { PanelHelper } from "@/mixins/PanelHelper";
import { config } from "@/config/config";
import { registerComponents } from "./config/globalComponents";
import VCalendar from 'v-calendar';
import Select2 from 'vue3-select2-component';
import VueEasymde from 'vue3-easymde'
import "easymde/dist/easymde.min.css"
import { Dialog, DialogPanel } from '@headlessui/vue'
import Vuelidate from '@vuelidate/core';

const app = createApp(App);
app.config.globalProperties.$axios = axios;
app.use(router);
app.use(store);  // Ensure store is added before other plugins/components
app.mixin(config);
app.mixin(globalHelpers);
app.mixin(PanelHelper);
app.use(VCalendar, {});
app.component('Select2', Select2);
app.use(VueEasymde);
app.use(Dialog, DialogPanel);
app.use(Vuelidate);

// Register Global Components
registerComponents(app);

app.mount("#app");
