<style>
/* Background Animation */
.bg-animated {
	background: linear-gradient(135deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6), rgba(24, 24, 74, 0.6));
	position: relative;
	overflow: hidden;
	z-index: 0;
	/* Keeps background behind the content */
}

/* Bubble Animation */
.animation-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 0;
}

.bubble {
	position: absolute;
	border-radius: 50%;
	border: 4px solid;
	opacity: 0.8;
	animation: move 15s linear infinite;
	animation-timing-function: ease-in-out;
	pointer-events: none;
	/* Ensure bubbles do not block clicks */
}

.bubble:nth-child(1) {
	width: 10px;
	height: 10px;
	top: -50px;
	left: 50%;
	border-color: rgba(0, 255, 255, 0.6);
	/* Bright cyan */
	animation-delay: 0s;
}

.bubble:nth-child(2) {
	width: 15px;
	height: 15px;
	top: -70px;
	left: 30%;
	border-color: rgba(255, 0, 255, 0.6);
	/* Bright magenta */
	animation-delay: 2s;
}

.bubble:nth-child(3) {
	width: 20px;
	height: 20px;
	top: -60px;
	left: 80%;
	border-color: rgba(255, 255, 0, 0.6);
	/* Bright yellow */
	animation-delay: 4s;
}

.bubble:nth-child(4) {
	width: 12px;
	height: 12px;
	top: -90px;
	left: 40%;
	border-color: rgba(0, 255, 0, 0.6);
	/* Bright green */
	animation-delay: 6s;
}

/* Animation Keyframes */
@keyframes move {
	0% {
		transform: translateY(0) translateX(0);
	}

	100% {
		transform: translateY(100vh) translateX(100vw);
	}
}

/* Floating Animation */
.animated-image {
	animation: float 6s ease-in-out infinite;
}

@keyframes float {
	0% {
		transform: translateY(0);
	}

	50% {
		transform: translateY(-10px);
	}

	100% {
		transform: translateY(0);
	}
}

/* Job Post Item */
.job-post-item {
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	transition: transform 0.3s ease, box-shadow 0.3s ease;
	margin-bottom: 20px;
	margin-top: 30px;
	position: relative;
	/* Added relative positioning */
}

.job-post-item:hover {
	transform: translateY(-5px);
	box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

/* Scroll Behavior */
html {
	scroll-behavior: smooth;
}

/* Inline Flex Styles */
a.inline-flex {
	position: relative;
	z-index: 10;
	/* Adjust as needed */
}

/* Circular Progress Bars */
.circular-progress-bars {
	display: flex;
	justify-content: space-around;
	align-items: center;
	gap: 10px;
}

.circular-progress {
	text-align: center;
	width: 150px;
	position: relative;
}

.circle {
	margin-left: 30px;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.1);
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

.circle:before {
	content: '';
	position: absolute;
	width: 70px;
	height: 70px;
	background-color: #0f172a;
	border-radius: 50%;
	z-index: 1;
}

.circle:after {
	content: attr(data-percent) '%';
	position: absolute;
	color: white;
	font-size: 1rem;
	font-weight: bold;
	z-index: 2;
	/* Ensure text is above inner circle */
}

/* Text Alignment */
p {
	margin-right: 0;
	margin-top: 6px;
	/* Adjust margin to fit the layout */
}

/* Styles for Email Input */
input[type="email"] {
	position: relative;
	z-index: 10;
	/* Ensure email input is clickable */
	padding: 10px;
	border: 1px solid rgba(255, 255, 255, 0.6);
	border-radius: 5px;
	background-color: rgba(255, 255, 255, 0.1);
	color: white;
	font-size: 16px;
	width: 100%;
	/* Adjust width as needed */
}

/* Container Styles */
.container {
	position: relative;
	/* Keep the container relative */
	z-index: 5;
	/* Ensure it's above the background elements */
}

.connect-button {
	background-color: transparent !important;
	/* Green background */
	color: white;
	/* White text */
	padding: 12px 20px;
	/* Padding */
	text-align: center;
	/* Center text */
	text-decoration: none;
	/* Remove underline */
	display: inline-block;
	/* Display as inline-block */
	font-size: 16px;
	/* Font size */
	margin: 4px 2px;
	/* Margin */
	cursor: pointer;
	/* Pointer cursor on hover */
	border: none;
	/* Remove border */
	border-radius: 5px;
	/* Rounded corners */
	z-index: 20;
	/* Ensure it's above background elements */
	position: relative;
	/* Ensure position is relative for stacking context */
	transition: background-color 0.3s;
	/* Smooth background change */
}



/* Adjust z-index for container */
.container {
	position: relative;
	/* Keep the container relative */
	z-index: 5;
	/* Ensure it's above the background elements */
}

/* Ensure all other elements do not interfere with button */
.animation-container,
.bg-animated {
	z-index: 0;
	/* Keep background layers below */
}

.asdfasf {
	background-color: green !important;
}
</style>

<template>
	<main>
		<div class="bg-animated pt-10 sm:pt-16 lg:overflow-hidden lg:pt-8 lg:pb-14">
			<div class="animation-container">
				<!-- Add more bubble divs for a fuller effect -->
				<div class="bubble"></div>
				<div class="bubble"></div>
				<div class="bubble"></div>
				<div class="bubble"></div>
				<div class="bubble"></div>
				<div class="bubble"></div>
				<div class="bubble"></div>
				<div class="bubble"></div>
				<div class="bubble"></div>
				<div class="bubble"></div>
				<div class="bubble"></div>
				<div class="bubble"></div>
				<div class="bubble"></div>
			</div>
			<div class="mx-auto max-w-7xl lg:px-8">
				<div class="lg:grid lg:grid-cols-2 lg:gap-8">
					<div
						class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left">
						<div class="lg:py-24">
							<a href="#first-job-post"
								class="inline-flex items-center rounded-full bg-black p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base">
								<span
									class="rounded-full bg-gradient-to-r from-teal-500 to-cyan-600 px-3 py-0.5 text-sm font-semibold leading-5 text-white">
									Search a Job
								</span>
								<span class="ml-4 text-sm">Click here</span>
								<ChevronRightIcon class="ml-2 h-5 w-5 text-gray-300" aria-hidden="true" />
							</a>


							<h1
								class="mt-4 text-4xl font-bold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
								<span class="block">Dev Integrity</span>
								<span
									class="block bg-gradient-to-r from-teal-200 to-cyan-400 bg-clip-text pb-3 text-transparent sm:pb-5">
									Custom Software Development & Human Resources Company Delivering at Scale
								</span>
							</h1>
							<p class="text-base text-white sm:text-xl lg:text-lg xl:text-xl">Dev Integrity helps
								businesses transform and gain a competitive edge through superior custom-made
								applications. We prioritize long-term relationships by supporting, maintaining, and
								sustaining their mission-critical applications. Email us to get Quotation info@devintegrity.com or hr@devintegrity.com </p>
							<div class="mt-10 sm:mt-12">
								<form @submit.prevent="sendEmail" class="sm:mx-auto sm:max-w-xl lg:mx-0">
									<div class="sm:flex">
										<div class="min-w-0 flex-1 mr-3">
											<label for="email" class="sr-only">Email address</label>
											<input id="email" type="email" v-model="email"
												placeholder="Enter your email" class="block w-full rounded-md border-0 bg-gray-800 px-4 py-3 text-base
											text-white placeholder-white focus:outline-none focus:ring-2
											focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900" required />
										</div>
										<div class="bg-animated">
											<div class="animation-container">
												<!-- Bubble animations -->
											</div>
											<div class="container">
												<!-- Other content -->
												<div class=" sm:mt-0 asdfasf">
													<button type="submit"
														class="block w-full rounded-md bg-gradient-to-r from-teal-500 to-cyan-600 py-3 px-4 font-medium text-white shadow-lg transition duration-300 ease-in-out hover:from-teal-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900"
														style="padding: 13px 12px !important;">Get Quotation</button>
												</div>
												<!-- More content -->
											</div>
										</div>

									</div>
								</form>
							</div>
						</div>
					</div>
					<div class="mt-12 -mb-16 sm:-mb-48 lg:relative lg:m-0">
						<div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
							<img class="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none rounded-lg shadow-2xl animated-image"
								src="/assets/images/aboutus/high_res_hero-services-img.webp"
								alt="Custom Software Development Illustration" />
						</div>
					</div>
				</div>
			</div>
		</div>


		<main v-if="$store?.getters" class="antialiased">
			<div id="find-a-job">
				<div class="lg:max-w-5xl mx-auto mt-20 md:mt-32 md:px-6 xl:px-0 search-ui">
					<div class="px-4 sm:px-6 md:px-0">
						<h2 class="text-2xl sm:text-3xl text-gray-900 font-bold">Search jobs</h2>
						<div class="mt-6 sm:mt-8" id="first-job-post">
							<div class="grid sm:grid-cols-2 gap-2 sm:gap-3">
								<div>
									<span class="hidden sm:block text-gray-600 font-medium">Search for Job Title</span>
									<div id="searchbox" class="sm:mt-1">
										<div class="ais-SearchBox">
											<div class="ais-SearchBox-form">
												<input class="ais-SearchBox-input" v-model="filters.job_title"
													@keyup="setFilter('job_title', $event.target.value, action)"
													type="text" maxlength="512" />
												<span class="ais-SearchBox-submit">
													<svg class="ais-SearchBox-submitIcon" width="10" height="10"
														viewBox="0 0 40 40">
														<path
															d="M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z" />
													</svg>
												</span>
											</div>
										</div>
									</div>
								</div>

								<div>
									<span class="hidden sm:block text-gray-600 font-medium">Search for Place and press
										Enter</span>
									<div id="aa-search-input" class="sm:mt-1">
										<div class="ais-SearchBox">
											<div class="ais-SearchBox-form">
												<span class="algolia-autocomplete"
													style="position: relative; display: inline-block; direction: ltr;">
													<input class="aa-SearchBox-input ais-SearchBox-input aa-input"
														type="text" v-model="filters.company_hq"
														@keyup.enter="setFilter('company_hq', $event.target.value, action)"
														style="position: relative; vertical-align: top;" />
													<span class="ais-SearchBox-submit">
														<svg class="ais-SearchBox-submitIcon" height="10" width="10"
															viewBox="0 0 14 20">
															<path
																d="M7 0C3.13.0.0 3.13.0 7c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5C5.62 9.5 4.5 8.38 4.5 7S5.62 4.5 7 4.5 9.5 5.62 9.5 7 8.38 9.5 7 9.5z" />
														</svg>
													</span>
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div id="hits" class="mt-0">
						<div>
							<div class="ais-Hits">
								<ol v-if="jobs.length" class="ais-Hits-list">
									<li v-for="(job, index) in jobs" :key="index" :value="job.id"
										class="job-post-item ais-Hits-item">
										<div class="grid text-sm text-gray-600 bg-white border-t border-gray-200 p-4 sm:p-6"
											style="grid-template-columns: auto minmax(0, 1fr)">
											<img class="h-8 sm:h-10 md:h-11 w-8 sm:w-10 md:w-11 bg-white border border-gray-100 lazyload sm:mr-4 rounded-full"
												:src="baseURL + '/' + job.company_logo_link" height="44" width="44"
												alt="Upshot logo" />
											<div class="col-start-1 sm:col-start-2 row-start-2 sm:row-start-1">
												<h2
													class="text-lg sm:text-xl md:text-2xl text-gray-800 font-bold mt-2 sm:mt-0">
													<a class="hover:text-purple transition-colors"
														:href="'/job-detail/' + job._id">
														{{ job.job_title }}
													</a>
												</h2>
												<h3 class="text-base md:text-lg text-gray-800">
													<a class="hover:text-purple transition-colors"
														:href="job.company_website_url">
														{{ job.company_name }}
													</a>
												</h3>
												<h5 v-if="job.job_description.length < 400">{{ job.job_description }}
												</h5>
												<h5 v-else>{{ job.job_description.substring(0, 400) + "..." }}</h5>
											</div>
											<div class="col-start-1 sm:col-start-2 col-end-2 sm:col-end-4">
												<div class="flex flex-row flex-wrap mt-3 space-x-1 sm:space-x-2">
													<ul class="leading-relaxed sm:leading-normal">
														<li class="inline">
															<h4 class="inline">
																<a class="hover:text-purple transition-colors"
																	href="#">{{ job.company_hq }}</a>
															</h4>
														</li>
													</ul>
													<span aria-hidden="true">·</span>
													<h4 class="leading-relaxed sm:leading-normal">
														<a class="hover:text-purple transition-colors" href="/design/">
															{{ job.job_category.name }}
														</a>
													</h4>
													<span aria-hidden="true">·</span>
													<ul class="leading-relaxed sm:leading-normal">
														<li class="inline-block">
															<h4 class="inline">
																<a class="hover:text-purple transition-colors"
																	href="/full-time/">Full-Time</a>
															</h4>
														</li>
													</ul>
												</div>
												<ul
													class="flex flex-wrap -mb-1 sm:-mb-0.5 mt-1 md:mt-2 -mx-1 sm:-mx-0.5">
													<li v-for="(job_skill, index) in job.job_skill" :key="index"
														:value="job_skill.id"
														class="border border-gray-300 m-1 sm:m-0.5 rounded-md group hover:border-purple transition-colors">
														<p class="block group-hover:text-purple p-1">{{ job_skill.name
															}}</p>
													</li>
												</ul>
											</div>
											<div
												class="col-start-3 row-start-1 flex items-baseline justify-end self-center sm:self-auto">
												<div class="inline-block leading-loose">
													<time datetime="2022-07-13T08:00:00"></time>
													<span>{{ vpDate(job.createdAt) }}</span>
												</div>
											</div>
										</div>
									</li>
								</ol>
								<ol v-else>
									<div id="hits" class="mt-0">
										<div>
											<div class="ais-Hits ais-Hits--empty">
												<div class="ais-Hits-empty">
													<p class="text-center">
														No results found for your search. Try adjusting your filters.
													</p>
												</div>
											</div>
										</div>
									</div>
								</ol>
							</div>
						</div>
					</div>
					<div v-if="paginationObject && paginationObject?.perPage">
						<Pagination :pagination="paginationObject" :fetchAction="action" />
					</div>
				</div>

			</div>



		</main>
		<div class="bg-animated relative isolate overflow-hidden bg-animated py-24 sm:py-32">
			<div class="mx-auto max-w-7xl px-2 lg:px-2">
				<div class="mx-auto max-w-4xl lg:mx-0">
					<h2 class="text-4xl font-bold tracking-tight text-white sm:text-6xl">Award Winning Support &
						Expertise</h2>
					<p class="mt-6 text-lg leading-8 text-white">
						At Dev Integrity, our mission is to deliver value, service, transparency, and quality to all. We
						specialize in providing contract and permanent IT, Marketing, HR, and Accountancy recruitment
						across the globe, catering to businesses of all sizes—from Small to Medium Enterprises (SMEs) to
						large international corporations. Our recruitment and technology solutions empower both
						businesses and humanity.
					</p>
					<p class="mt-6 text-lg leading-8 text-white">
						Our vision is to care for people and businesses with a human-centric approach. Dev Integrity
						prides itself on unparalleled industry and sector knowledge. While we leverage all leading
						IT-specific and general job boards, nothing compares to our carefully curated database and
						in-depth knowledge of "who's who" in key industry sectors.
					</p>
					<p class="mt-6 text-lg leading-8 text-white">
						In short, there’s no need to look anywhere else for your IT staffing needs. But don’t just take
						our word for it. Here’s what some of our current clients say:
					</p>
					<!-- Circular Progress Bars Section -->
					<div class="circular-progress-bars mt-10 flex justify-between">

						<!-- ARE A VALUED ADVISOR -->
						<div class="circular-progress">
							<div class="circle" data-percent="94"
								style="background: conic-gradient(#06b6d4 calc(94%), transparent calc(94%));"></div>
							<p class="text-center mt-4 text-white text-lg font-semibold">ARE A VALUED ADVISOR</p>
						</div>

						<!-- DELIVERS WHAT’S PROMISED -->
						<div class="circular-progress">
							<div class="circle" data-percent="92"
								style="background: conic-gradient(#06b6d4 calc(92%), transparent calc(92%));"></div>
							<p class="text-center mt-4 text-white text-lg font-semibold">DELIVERS WHAT’S PROMISED</p>
						</div>

						<!-- KEEPS ME INFORMED -->
						<div class="circular-progress">
							<div class="circle" data-percent="94"
								style="background: conic-gradient(#06b6d4 calc(94%), transparent calc(94%));"></div>
							<p class="text-center mt-4 text-white text-lg font-semibold">KEEPS ME INFORMED</p>
						</div>

						<!-- MY CAREER PRIORITIES -->
						<div class="circular-progress">
							<div class="circle" data-percent="96"
								style="background: conic-gradient(#06b6d4 calc(96%), transparent calc(96%));"></div>
							<p class="text-center mt-4 text-white text-lg font-semibold">MY CAREER PRIORITIES</p>
						</div>

					</div>
				</div>
			</div>
		</div>

		<div class="bg-gray-50 py-24 sm:py-32">
			<div class="mx-auto max-w-7xl px-6 lg:px-8">
				<div class="mx-auto max-w-5xl lg:mx-0 text-center">
					<h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Core Services</h2>
					<p class="mt-6 text-lg leading-8 text-gray-500">
						We don’t just create apps; our services cover the entire app development lifecycle, from concept
						and design to prototyping and UX, delivery, and distribution. Our apps solve real-world business
						problems, provide new and innovative ways of engaging with customers, and enable better working
						practices.
					</p>
				</div>
				<div class="mx-auto mt-16 max-w-5xl sm:mt-20 lg:mt-24 lg:max-w-none">
					<dl class="grid max-w-4xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
						<div v-for="feature in features" :key="feature.name"
							class="flex flex-col bg-white shadow-lg rounded-lg p-6 transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-2xl">
							<dt class="text-base font-semibold leading-7 text-cyan-600">
								<div class="flex mb-5 text-center">
									<img class="h-12 w-auto" :src="feature.href" alt="" />
								</div>
								<a :href="feature.url" class="mt-6 font-semibold text-cyan-600">
									{{ feature.name }}
									<span class="inset-0"></span>
								</a>
							</dt>
							<dd class="mt-2 flex flex-auto flex-col text-base leading-7 text-gray-900">
								<p class="flex-auto">{{ feature.description }}</p>
							</dd>
						</div>
					</dl>
				</div>
			</div>
		</div>


		<!-- Blog section -->
		<div class="relative bg-gray-50 py-16 sm:py-24 lg:py-0">
			<div class="relative">
				<div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
					<h2 class="text-lg font-semibold text-cyan-600">Learn</h2>
					<p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Helpful
						Resources</p>
					<p class="mx-auto mt-5 max-w-prose text-xl text-gray-500">Our Blog is Written by Experts. Explore
						the depths of IT expertise on our blog, where our team of seasoned professionals unravels the
						intricate threads of the tech world. </p>
				</div>
				<div
					class="mx-auto mt-12 grid max-w-md gap-8 px-4 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:grid-cols-3 lg:px-8">
					<div v-for="post in blogPosts" :key="post.id"
						class="flex flex-col overflow-hidden rounded-lg shadow-lg">
						<div class="flex-shrink-0">
							<img class="h-48 w-full object-cover" :src="post.imageUrl" alt="" />
						</div>
						<div class="flex flex-1 flex-col justify-between bg-white p-6">
							<div class="flex-1">
								<p class="text-sm font-medium text-cyan-600">
									<a :href="post.category.href" class="hover:underline">{{ post.category.name
										}}</a>
								</p>
								<a :href="post.href" class="mt-2 block">
									<p class="text-xl font-semibold text-gray-900">{{ post.title }}</p>
									<p class="mt-3 text-base text-gray-500">{{ post.preview }}</p>
								</a>
							</div>
							<div class="mt-6 flex items-center">
								<div class="flex-shrink-0">
									<a :href="post.author.href">
										<img class="h-10 w-10 rounded-full" :src="post.author.imageUrl"
											:alt="post.author.name" />
									</a>
								</div>
								<div class="ml-3">
									<p class="text-sm font-medium text-gray-900">
										<a :href="post.author.href" class="hover:underline">{{ post.author.name
											}}</a>
									</p>
									<div class="flex space-x-1 text-sm text-gray-500">
										<time :datetime="post.datetime">{{ post.date }}</time>
										<span aria-hidden="true">&middot;</span>
										<span>{{ post.readingLength }} read</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>



	</main>
</template>
<script>
export default {
	meta() {
		console.log('Setting meta tags');
    return {
      title: 'Home - Dev Integrity',
      meta: [
        { name: 'description', content: 'Learn more about Dev Integrity, a leading software development and human resources company.' },
        { name: 'keywords', content: 'home, Dev Integrity, software development, human resources, custom applications' },
        { property: 'og:title', content: 'Home - Dev Integrity' },
        { property: 'og:description', content: 'Discover how Dev Integrity helps businesses transform through superior custom software solutions.' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: window.location.href }, // Ensure the URL is dynamic
      ],
    };
  },
	data() {
		
		return {
			email: '',
			filters: {
				job_title: '',
				company_hq: ''
			},
			jobs: [], // Local variable to hold jobs fetched from Vuex
			paginationObject: null,
			action: "fetchJobs",
			baseURL: process.env.VUE_APP_API_URL, // Assuming you have pagination data
		};
	},

	methods: {
		async sendEmail() {
			try {
				// Ensure `this.email` is a string
				const emailPayload = { email: String(this.email) }; // You can remove 'application' if it's not needed

				// Pass only the stringified email as payload to the Vuex action
				await this.$store.dispatch('postEmail', emailPayload);
				this.createToast({ message: 'We will reach you as soon as possible!', type: 'success' });
			} catch (error) {
				this.createToast({ message: 'Failed to send email. Please try again.', type: 'error' });
			}
		},
		createToast(payload) {
			var toastId = Math.floor(Math.random() * 999999);
			// Use this.$store.commit to commit mutations to Vuex
			this.$store.commit("setToast", { ...payload, id: toastId });
			setTimeout(() => {
				this.$store.commit("removeToast", toastId);
			}, 9000);
		},
		async getFilteredData() {
			const response = await this.$store.dispatch(this.action, {
				body: {},
				options: {
					loader: true,
					tableSkeleton: true,
				},
			});
		},

		async fetchJobsCreate() {
			await this.$store.dispatch('fetchJobsCreate', {
				body: {},
			});
		},

		// Optional method if you need to manually update `jobs`
		updateJobs() {
			this.jobs = this.$store.getters.getJobs || []; // Adjust the getter name as needed
			this.paginationObject = this.$store.getters.getJobs?.pagination || null; // Adjust as needed
		},
	},
	mounted() {
		this.resetFilters();
		this.getFilteredData();
		this.fetchJobsCreate();
		this.updateJobs(); // Initialize jobs with current Vuex data
	},

	watch: {
		// Watch for changes in the `getJobs` getter
		'$store.getters.getJobs': {
			immediate: true, // Fetch data immediately on load
			handler(newJobs) {
				this.jobs = newJobs || []; // Update local `jobs` variable
				this.paginationObject = newJobs?.pagination || null; // Update pagination if present
			}
		}
	}
};
</script>



<script setup>
import { ArrowTopRightOnSquareIcon, ChevronRightIcon } from '@heroicons/vue/20/solid'

const links = [
	{ name: 'Open roles', href: '#' },
	{ name: 'Internship program', href: '#' },
	{ name: 'Our values', href: '#' },
	{ name: 'Meet our leadership', href: '#' },
]
const stats = [
	{ name: 'Offices worldwide', value: '12' },
	{ name: 'Full-time colleagues', value: '300+' },
	{ name: 'Hours per week', value: '40' },
	{ name: 'Paid time off', value: 'Unlimited' },
]
const features = [
	{
		name: 'AI-Powered HR Reports',
		description: 'Our AI-powered solution simplifies human resource management by analyzing LinkedIn data and resumes to generate detailed, actionable reports. These reports provide valuable insights, making HR processes such as hiring, talent management, and employee development more efficient and also align HR strategies, reducing time and effort for workforce potential.',
		href: '/assets/images/services/human-resouces-devintegrity.png',
		url: '/hrandmarketting'
	},
	{
		name: 'Human Resources Management',
		description: 'With our HR services, reclaim the power of your network to hire and grow. Our tailored solutions not only streamline your recruitment process but also enhance employee engagement and retention. By leveraging data-driven insights, we help you create a thriving workplace culture that aligns with your business objectives.',
		href: '/assets/images/services/Human_Resources.png',
		url: '/hrandmarketting'
	},
	{
		name: 'Mobile App Development',
		description: 'Partner with a reliable mobile app development team that dives deep into your business ecosystem. We craft user-centric applications that not only meet your functional requirements but also enhance user experience and engagement. Our expertise in various platforms ensures that your app is scalable, secure, and capable of driving your business forward.',
		href: '/assets/images/services/booking-ma.jpg',
		url: '/mobileAppDevelopment'
	},
	{
		name: 'Web Application Development',
		description: 'Maintain full control of your business processes as you grow your company through well-designed, secure, and bespoke web applications. Our development team utilizes the latest technologies to create scalable solutions tailored to your unique needs, ensuring that your web application is efficient, user-friendly, and equipped with the features necessary to facilitate seamless operations.',
		href: '/assets/images/services/Web_Application_development.png',
		url: '/webDevelopment'
	},
	{
		name: 'App Maintenance & Support',
		description: 'We maintain, support, and sustain critical applications to keep your investment secure and allow your application to accelerate your business growth. Our proactive monitoring and regular updates ensure optimal performance and reliability, while our dedicated support team is always ready to address any issues, minimizing downtime and maximizing productivity.',
		href: '/assets/images/services/App_Maintenance_Support.png',
		url: '/appMaintinessAndSupport'
	},
	{
		name: 'E-Commerce Development',
		description: 'Creating strategic digital shopping solutions that deliver tangible business results. Our e-commerce development services boost your online store engagement, increase brand exposure, and optimize the customer journey. We focus on integrating secure payment gateways and user-friendly interfaces to enhance customer satisfaction and drive conversions.',
		href: '/assets/images/services/ECommerce_Development.png',
		url: '/eCommerce'
	},
	{
		name: 'CMS Development',
		description: 'Get a result-driven website that captures your brand, improves your conversion rates, and maximizes your revenue to move your business forward. Our Content Management System (CMS) development services empower you to easily manage your website content, ensuring it is always up-to-date and engaging for your audience while providing robust analytics to track performance.',
		href: '/assets/images/services/CMS_Development.png',
		url: '/webDevelopment'
	},
	{
		name: 'API Integration',
		description: 'Streamlining business processes for optimized results with seamless third-party API integration services. We help you connect disparate systems and applications, ensuring smooth data flow and enhanced functionality. Our integration solutions not only save time but also improve data accuracy and consistency across your organization.',
		href: '/assets/images/services/Api_Integration.png',
		url: '/webDevelopment'
	},
	{
		name: 'DevOps',
		description: 'We design impactful and fully tested experiences for your digital products. Our DevOps services help you shorten release cycles, reduce costs, and stay ahead of the competition. By fostering collaboration between development and operations teams, we ensure continuous delivery and deployment, improving overall efficiency and responsiveness to market changes.',
		href: '/assets/images/services/Dev_Ops.png',
		url: '/webDevelopment'
	},
];

const blogPosts = [
	{
		id: 1,
		title: 'Boost your conversion rate',
		href: '/blog-detail',
		date: 'Mar 16, 2020',
		datetime: '2020-03-16',
		category: { name: 'Article', href: '#' },
		imageUrl:
			'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80',
		preview:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.',
		author: {
			name: 'Roel Aufderehar',
			imageUrl:
				'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
			href: '#',
		},
		readingLength: '6 min',
	},
	{
		id: 2,
		title: 'How to use search engine optimization to drive sales',
		href: '/blog-detail',
		date: 'Mar 10, 2020',
		datetime: '2020-03-10',
		category: { name: 'Video', href: '#' },
		imageUrl:
			'https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80',
		preview:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facilis asperiores porro quaerat doloribus, eveniet dolore. Adipisci tempora aut inventore optio animi., tempore temporibus quo laudantium.',
		author: {
			name: 'Brenna Goyette',
			imageUrl:
				'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
			href: '#',
		},
		readingLength: '4 min',
	},
	{
		id: 3,
		title: 'Improve your customer experience',
		href: '/blog-detail',
		date: 'Feb 12, 2020',
		datetime: '2020-02-12',
		category: { name: 'Case Study', href: '#' },
		imageUrl:
			'https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80',
		preview:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae magni placeat saepe molestiae, sed excepturi cumque corporis perferendis hic.',
		author: {
			name: 'Daniela Metz',
			imageUrl:
				'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
			href: '#',
		},
		readingLength: '11 min',
	},
]
</script>