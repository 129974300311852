<template>
    <main>

        <div class="relative overflow-hidden bg-gray-50 py-16">
            <div class="lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
                <div class="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
                    <svg class="absolute top-12 left-full translate-x-32 transform" width="404" height="384" fill="none"
                        viewBox="0 0 404 384">
                        <defs>
                            <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20"
                                patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                    </svg>
                    <svg class="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform" width="404"
                        height="384" fill="none" viewBox="0 0 404 384">
                        <defs>
                            <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20"
                                patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
                    </svg>
                    <svg class="absolute bottom-12 left-full translate-x-32 transform" width="404" height="384" fill="none"
                        viewBox="0 0 404 384">
                        <defs>
                            <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20"
                                patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
                    </svg>
                </div>
            </div>
            <div class="relative px-2 sm:px-2 lg:px-2">
                <div class="mx-auto max-w-6xl text-lg">
                    <h1>
                          <span
                            class="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                            Privacy and Cookies Policy
                        </span>
                    </h1>
                </div>
                <div class="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500">
                    <div class="elementor-element elementor-element-3b3d0baf color-scheme-inherit text-left elementor-widget elementor-widget-text-editor"
                        data-id="3b3d0baf" data-element_type="widget" data-widget_type="text-editor.default">
                        <div class="elementor-widget-container text-black">
                            <p>DevIntegrity helps businesses connect with one another by providing web applications, browser extensions, and other online services.</p>
                            <p>We are committed to respecting everyone's privacy and design our services with this goal in mind, in accordance with European Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regards to the processing of personal data and on the free movement of such data and repealing Directive 95/46/EC (“GDPR”) and applicable data protection laws.</p>
                            <p>In order to satisfy our customers’ expectations and to ensure the respect of data subjects’ rights, we regularly improve internal processes.</p>
                            <p>This Privacy Policy describes what data we collect and how we process it. If you have any questions, please reach out, we'll be glad to help: privacy@devintegrity.com.</p>
                            <h3>This Privacy applies to all services offered by DevIntegrity including:</h3>
                            <ul>
                                <li>The web platform</li>
                                <li>Our public API</li>
                                <li>Our Browser extension</li>
                                <li>G Suite addons</li>
                            </ul>
                            <h4>Contact Info</h4>
                            <p>DevIntegrity Technologies</p>
                            <p>Email: info@devintegrity.com</p>
                            <h4>What information does DevIntegrity collect?</h4>
                            <p>Any use of the web application, browser extension and/or API access is bound by these Terms of Service plus the following specific terms:</p>
                            <h5>Types of Data collected</h5>
                            <p>Among the types of Personal Data that this Application collects, by itself or through third parties, there are: first name; last name; email address; billing address; Tracker; Usage Data. Complete details on each type of Personal Data collected are provided in the dedicated sections of this privacy policy or by specific explanation texts displayed prior to the Data collection.</p>
                            <p>Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically when using this Application. Unless specified otherwise, all Data requested by this Application is mandatory and failure to provide this Data may make it impossible for this Application to provide its services.</p>
                            <p>In cases where this Application specifically states that some Data is not mandatory, Users are free not to communicate this Data without consequences to the availability or the functioning of the Service.</p>
                            <p>Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner. Any use of Cookies – or of other tracking tools — by this Application or by the owners of third-party services used by this Application serves the purpose of providing the Service required by the User, in addition to any other purposes described in the present document and in the Cookie Policy.</p>
                            <p>Users are responsible for any third-party Personal Data obtained, published or shared through this Application.</p>

                            <h4>When you are on our website</h4>
                            <p>When you're using our website and not limited to, we track your usage. This includes statistics such as which pages you visit and how long you look at them. This information may include but is not limited to IP address, geolocalisation information, browser type and information about the use of our website, including a history of the pages you view.</p>
                            <p>If you create an account, we request information including, but not limited to: your email, your name and the company you work for. All this information is used to personalize your experience and send you onboarding emails and invoices.</p>
                            <p>In cases of elevated fraud risk, we might ask for a phone number for verification purposes (we will never use it for marketing purposes). If you become a paying customer, we will collect your payment card information.</p>

                            <h4>When you use our services</h4>
                            <p>Whether you use our services and not limited to, through the API, the website, G-Suite add-ons or the browser extensions we provide, we monitor your usage and log the requests that you make. These logs can include IP addresses, geolocalization information, browser type and other relevant information. We destroy these logs after 3 months at the latest.</p>
                            <p>When using our services, you might also be asked to send us some data or data files.</p>

                            <h4>When you contact our support team</h4>
                            <p>If you reach out via email or chat, we keep a record of the conversations and any other data you might send during those exchanges. When you delete your account, those conversations are removed after a period of no more than 6 months.</p>

                            <h4>When someone uses our mail tracking service</h4>
                            <p>Our users can send emails through our platform and get tracking information on them. We let users know if we think their emails have been opened, where and how many times.</p>
                            <p>To offer these features, we store certain details to help identify the emails, such as the subject of the email, the date and time at which the email was sent and information about the recipient(s). However we don’t store the body of the concerned email. Where possible, we store the data in an anonymised form.</p>

                            <h4>Data we store</h4>
                            <p>We store the emails and content sent via DevIntegrity for up to a maximum of 10 years, depending on the user and customer preferences, in order to provide necessary services and ensure compliance with regulatory authorities.</p>

                            <h4>Cookies</h4>
                            <p>We use cookies on our website for many purposes. For example, we may use cookies to allow you to log in, to save your language preferences or to help protect our service.</p>
                            <p>You can control your cookies by using your browser settings. We process your information in accordance with our Cookies Policy, found at the following link: https://devintegrity.com/cookies-policy.</p>

                            <h4>Your rights</h4>
                            <p>Under the GDPR, you have the right to access, rectify, erase, restrict, or object to the processing of your data. To exercise these rights, please contact us via email at privacy@devintegrity.com.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
