<template>

    <main>


        <main class="antialiased">
            <div class="lg:max-w-6xl mx-auto px-4 sm:px-6 xl:px-0">
                <div class="pt-12 md:pt-32">
                    <h1
                        class="text-4xl md:text-6xl lg:text-7xl text-gray-900 font-extrabold md:leading-tighter lg:leading-tighter tracking-tight break-words md:break-normal mt-6 md:mt-7">
                        {{ this.$store.getters.getJobDetail.data[0]?.job_title }}</h1>
                    <div class="flex items-center mt-3 md:mt-4">
                        <div class="mr-3"><img
                                class="bg-white border border-gray-100 h-9 md:h-12 w-9 md:w-12 rounded-full lazyautosizes lazyloaded"
                                :src="baseURL + '/' + this.$store.getters.getJobDetail.data[0]?.company_logo_link"
                                height="400" width="400" alt="Octav logo" sizes="48px"></div>
                        <h2
                            class="text-xl md:text-3xl text-gray-900 font-medium leading-none sm:leading-none md:leading-none break-words md:break-normal">
                            <a class="hover:text-purple transition-colors" href="#">{{
                            this.$store.getters.getJobDetail.data[0]?.company_name }}</a>
                        </h2>
                    </div>
                </div>
                <div class="pb-20 pt-10 md:py-24 lg:py-28">
                    <div class="lg:max-w-5xl grid lg:grid-cols-4" style="grid-template-rows:auto 1fr">
                        <div class="lg:col-span-3 row-start-2 lg:row-span-3 mt-20 lg:mt-0">
                            <div class="prose">
                                <h2 id="about-octav">About {{ this.$store.getters.getJobDetail.data[0]?.company_name }}
                                </h2>
                                <p>{{ this.$store.getters.getJobDetail.data[0]?.company_description }}</p>

                                <h2 id="what-you-will-work-on">Job Description </h2>
                                <p>{{ this.$store.getters.getJobDetail.data[0]?.job_description }}</p>
                            </div>
                            <div class="mt-14 md:mt-20"><a
                                    class="inline-block w-full sm:w-auto sm:w-auto text-white font-semibold text-center bg-purple border border-transparent px-10 py-2.5 rounded-md hover:bg-gray-900 transition-colors"
                                    href="#" target="_blank" rel="noopener">Apply now</a>
                                <p class="max-w-prose text-gray-600 italic leading-relaxed mt-4">Please let <span
                                        class="font-semibold">{{ this.$store.getters.getJobDetail.data[0]?.company_name
                                        }}</span> know you found this position on
                                    Dev Integrity Jobs as a way to support us so we can keep providing you with quality
                                    crypto jobs.</p>
                            </div>
                        </div>
                        <div class="lg:col-start-4 row-start-1 lg:row-start-1">
                            <div>
                                <h3 class="text-xs text-gray-500 font-medium tracking-wide uppercase">Location</h3>
                                <ul class="text-gray-600 leading-relaxed mt-1">
                                    <li class="inline"><span class="hover:text-purple transition-colors">{{
                            this.$store.getters.getJobDetail.data[0]?.company_hq }}</span><span
                                            aria-hidden="true">,&nbsp;</span></li>
                                    <li class="inline"><span class="hover:text-purple transition-colors">{{
                            this.$store.getters.getJobDetail.data[0]?.job_type?.name }}</span></li>
                                </ul>
                            </div>
                            <div class="mt-6">
                                <h3 class="text-xs text-gray-500 font-medium tracking-wide uppercase">Job type</h3>
                                <ul class="text-gray-600 leading-relaxed mt-1">
                                    <li class="inline-block"><span class="hover:text-purple transition-colors">{{
                            this.$store.getters.getJobDetail.data[0]?.job_type?.name }}</span></li>
                                </ul>
                            </div>
                            <!-- <div class="mt-6">
                                <h3 class="text-xs text-gray-500 font-medium tracking-wide uppercase">Compensation</h3>
                                <div class="text-gray-600 mt-1"><span>C$150K – C$200K</span>
                                    <span class="block mt-2"><a class="hover:text-purple transition-colors"
                                            href="/jobs-that-pay-in-crypto/">The option of getting paid in digital
                                            currency</a></span>
                                </div>
                            </div> -->
                            <div class="mt-6">
                                <h3 class="text-xs text-gray-500 font-medium tracking-wide uppercase">Skills</h3>

                                <ul class="text-gray-600 leading-relaxed mt-1">

                                    <li v-for="(job_skill, index) in this.$store.getters.getJobDetail.data[0]?.job_skill"
                                        :key="index" :value="job_skill.id" class="inline"><span
                                            class="hover:text-purple transition-colors"> {{ job_skill.name }}
                                            ,&nbsp;</span>
                                    </li>

                                </ul>
                            </div>
                            <div class="mt-6">
                                <h3 class="text-xs text-gray-500 font-medium tracking-wide uppercase">Share</h3>
                                <ul class="text-gray-600 leading-relaxed mt-2">
                                    <li class="inline-block mr-3 md:mr-2"><a
                                            class="text-gray-500 hover:text-purple transition-colors" href="#"
                                            target="_blank" rel="noopener noreferrer"><svg
                                                class="h-5 w-5 rounded-md lazyloaded" aria-label="Facebook" role="img"
                                                viewBox="0 0 512 512">
                                                <rect fill="currentcolor" height="512" width="512" rx="15%"></rect>
                                                <path fill="#fff"
                                                    d="M355.6 330l11.4-74h-71v-48c0-20.2 9.9-40 41.7-40H370v-63s-29.3-5-57.3-5c-58.5.0-96.7 35.4-96.7 99.6V256h-65v74h65v182h80V330h59.6z">
                                                </path>
                                            </svg><span class="sr-only">Share on Facebook</span></a></li>
                                    <li class="inline-block mr-3 md:mr-2"><a
                                            class="text-gray-500 hover:text-purple transition-colors" href="#"
                                            target="_blank" rel="noopener noreferrer"><svg
                                                class="h-5 w-5 rounded-md lazyloaded" aria-label="Twitter" role="img"
                                                viewBox="0 0 512 512">
                                                <rect fill="currentcolor" height="512" width="512" rx="15%"></rect>
                                                <path fill="#fff"
                                                    d="M437 152a72 72 0 01-40 12 72 72 0 0032-40 72 72 0 01-45 17 72 72 0 00-122 65 2e2 2e2.0 01-145-74 72 72 0 0022 94 72 72 0 01-32-7 72 72 0 0056 69 72 72 0 01-32 1 72 72 0 0067 50A2e2 2e2.0 0193 368a2e2 2e2.0 00309-179 2e2 2e2.0 0035-37">
                                                </path>
                                            </svg><span class="sr-only">Share on Twitter</span></a></li>
                                    <li class="inline-block mr-3 md:mr-2"><a
                                            class="text-gray-500 hover:text-purple transition-colors" href="#"
                                            target="_blank" rel="noopener noreferrer"><svg class="h-5 w-5 rounded-md"
                                                fill="#fff" aria-label="LinkedIn" role="img" viewBox="0 0 512 512">
                                                <rect fill="currentcolor" height="512" width="512" rx="15%"></rect>
                                                <circle cx="142" cy="138" r="37"></circle>
                                                <path stroke="#fff" stroke-width="66" d="M244 194v198M142 194v198">
                                                </path>
                                                <path
                                                    d="M276 282c0-20 13-40 36-40 24 0 33 18 33 45v105h66V279c0-61-32-89-76-89-34 0-51 19-59 32">
                                                </path>
                                            </svg><span class="sr-only">Share on LinkedIn</span></a></li>
                                    <li class="inline-block"><a
                                            class="text-gray-500 hover:text-purple transition-colors" href="#"
                                            target="_blank" rel="noopener noreferrer"><svg class="h-5 w-5 rounded-md"
                                                aria-label="Email" role="img" viewBox="0 0 512 512">
                                                <rect fill="currentcolor" height="512" width="512" rx="15%"></rect>
                                                <rect fill="#fff" height="256" width="356" x="78" y="128" rx="8%">
                                                </rect>
                                                <path fill="none" stroke="teal" stroke-width="20"
                                                    d="M434 128 269 292c-7 8-19 8-26 0L78 128m0 256 129-128m227 128L305 256">
                                                </path>
                                            </svg><span class="sr-only">Share by Email</span></a></li>
                                </ul>
                            </div>
                            <div class="mt-12"><a
                                    class="inline-block w-full sm:w-auto sm:w-auto text-white font-semibold text-center bg-purple border border-transparent px-10 py-2.5 rounded-md hover:bg-gray-900 transition-colors"
                                    href="#">Apply now</a>
                                <p class="max-w-prose text-gray-600 italic leading-relaxed mt-4">Please let <span
                                        class="font-semibold">{{ this.$store.getters.getJobDetail.data[0]?.company_name
                                        }}</span> know you found this position on
                                    Dev Integrity Jobs as a way to support us so we can keep providing you with quality
                                    jobs.</p>
                            </div>
                        </div>
                        <div class="lg:col-start-4 row-start-3 lg:row-start-2">
                            <div class="mt-14 sm:mt-12">
                                <h3 class="text-xs text-gray-500 font-medium tracking-wide uppercase">About {{
                            this.$store.getters.getJobDetail.data[0]?.company_name
                                    }}</h3>
                                <div class="text-gray-600 mt-1">
                                    <p><a class="hover:text-purple transition-colors" href="#">Company
                                            profile</a></p>
                                </div>
                            </div>
                            <div class="hidden lg:block mt-10"><a
                                    class="inline-flex items-center text-gray-600 group hover:text-purple transition-colors"
                                    href="/">Back to all crypto jobs
                                    <span class="group-hover:hidden" aria-hidden="true"><svg
                                            class="inline h-5 w-5 sm:ml-0.5 md:ml-1" viewBox="0 0 20 20"
                                            fill="currentcolor">
                                            <path
                                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414.0z">
                                            </path>
                                        </svg></span><span class="hidden group-hover:inline" aria-hidden="true"><svg
                                            class="inline h-5 w-5 sm:ml-0.5 md:ml-1" viewBox="0 0 20 20"
                                            fill="currentcolor">
                                            <path
                                                d="M10.293 5.293a1 1 0 011.414.0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z">
                                            </path>
                                        </svg></span></a></div>
                        </div>
                    </div>
                </div>
            </div>



        </main>



        <!-- CTA Section -->
        <div class="relative bg-gray-900">
            <div class="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
                <img class="h-full w-full object-cover"
                    src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&sat=-100"
                    alt="" />
                <div aria-hidden="true"
                    class="absolute inset-0 bg-gradient-to-r from-teal-500 to-cyan-600 mix-blend-multiply" />
            </div>
            <div class="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
                <div class="md:ml-auto md:w-1/2 md:pl-10">
                    <h2 class="text-lg font-semibold text-gray-300">Award winning support</h2>
                    <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">We’re here to help
                    </p>
                    <p class="mt-3 text-lg text-gray-300">Dev Integrity
                        Custom Software Development & Human Resources Company Delivering at Scale. Dev Integrity help
                        businesses to transform & gain competitive edge through delivering superior custom made
                        applications and prioritizing long-term relationship by supporting, maintaining & sustaining
                        their mission critical application.</p>

                </div>
            </div>
        </div>
    </main>


</template>

<script>
export default {
    data() {
        return {
            action: "fetchJobDetail",
            baseURL: process.env.VUE_APP_API_URL,
        };
    },
    methods: {

        async getFilteredData() {
            var jobData = {
                _id: this.$route.params.id
            }

            await this.$store.dispatch(this.action, {
                body: { ...jobData },
                options: {
                    loader: true,
                    tableSkeleton: true,
                },
            });
        },

    },
    created() {
        this.resetFilters();
        this.getFilteredData();
    },
};
</script>


<script setup>
import { ArrowTopRightOnSquareIcon, ChevronRightIcon } from '@heroicons/vue/20/solid'

</script>