<template>
    <main>
        <div class="relative overflow-hidden bg-gray-50 py-16">
            <div class="lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
                <div class="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
                    <svg class="absolute top-12 left-full translate-x-32 transform" width="404" height="384" fill="none" viewBox="0 0 404 384">
                        <defs>
                            <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                    </svg>
                </div>
            </div>
            <div class="relative px-2 sm:px-2 lg:px-2">
                <div class="mx-auto max-w-6xl text-lg">
                    <h1>
                        <span class="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                            Terms and Conditions
                        </span>
                    </h1>
                </div>
                <div class="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500">
                    <div class="elementor-element elementor-element-3b3d0baf color-scheme-inherit text-left elementor-widget elementor-widget-text-editor"
                        data-id="3b3d0baf" data-element_type="widget" data-widget_type="text-editor.default">
                        <div class="elementor-widget-container text-black">
                            <!-- Insert your Terms and Conditions content here -->
                            <p>These terms and conditions outline the rules and regulations for the use of DevIntegrity's Website.</p>
                            <p>By accessing this website we assume you accept these terms and conditions. Do not continue to use DevIntegrity if you do not agree to all of the terms and conditions stated on this page.</p>

                            <h3>License</h3>
                            <p>Unless otherwise stated, DevIntegrity and/or its licensors own the intellectual property rights for all material on DevIntegrity. All intellectual property rights are reserved. You may access this from DevIntegrity for your own personal use subjected to restrictions set in these terms and conditions.</p>
                            <ul>
                                <li>You must not:</li>
                                <li>Republish material from DevIntegrity</li>
                                <li>Sell, rent or sub-license material from DevIntegrity</li>
                                <li>Reproduce, duplicate or copy material from DevIntegrity</li>
                                <li>Redistribute content from DevIntegrity</li>
                            </ul>

                            <h3>Limitation of Liability</h3>
                            <p>DevIntegrity will not be held liable for any damages arising out of or in connection with your use of the website. This includes, without limitation, direct loss, business interruption, or loss of data.</p>

                            <h3>Terms & Conditions</h3>
                            <p>The following terms and conditions govern all use of the DevIntegrity Services. Unless otherwise specified, the terms of use detailed in this section apply generally when using this Application. DevIntegrity website and all content, services, and products available at, or through, the website, including, but not limited to, the DevIntegrity user management service ("devintegrity"), (taken together, the Services). The Services offered is subject to your acceptance without modification of all of the terms and conditions contained herein and all other operating rules, policies, and procedures that may be published from time to time on this Site by DevIntegrity (collectively, the "Agreement").</p>

                            <p>Please read this Agreement carefully before accessing or using the Services. By accessing or using any part of the website, you agree to become bound by the terms and conditions of this agreement. If you do not agree to all the terms and conditions of this agreement, then you may not access or use the Services. If these terms and conditions are considered an offer by devintegrity, acceptance is expressly limited to these terms. The Services are available only to individuals who are at least 18 years old.</p>

                            <h3>Our Contact Information</h3>
                            <p>DevIntegrity technologies</p>
                            <p>Email: <a href="mailto:info@devintegrity.com" class="text-indigo-600">info@devintegrity.com</a></p>

                            <h3>Your Account</h3>
                            <p>If you create an account on the Services, you are responsible for maintaining the security of your account, and you are fully responsible for all activities that occur under the account and any other actions taken in connection with the account. You must immediately notify DevIntegrity of any unauthorized uses of your account or any other breaches of security. DevIntegrity will not be liable for any acts or omissions by You, including any damages of any kind incurred as a result of such acts or omissions. You must be a human. Accounts registered and/or operated by "bots" and/or other automated systems are not permitted.</p>
                            <p>You must provide your legal full name, a valid email address (preferably from your company), and any other information requested in order to complete the sign-up and validation process. You must not, in the use of the Services, violate any laws in your jurisdiction (including but not limited to copyright or trademark laws). You must not create more than one account.</p>

                            <h3>General Obligations</h3>
                            <p>Any use of the web application, Chrome extension and/or API access is bound by these Terms of Service plus the following specific terms:</p>

                            <h3>Damage and Liability</h3>
                            <p>You expressly understand and agree that DevIntegrity shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to: damages for loss of profits, goodwill, use, data or other intangible losses (even if DevIntegrity has been advised of the possibility of such damages), resulting from your use of the service or third-party products that access data.</p>

                            <h3>Rate Limiting</h3>
                            <p>Abuse or excessively frequent requests to DevIntegrity may result in the temporary or permanent suspension of your account's access. devintegrity, in its sole discretion, will determine abuse or excessive usage. devintegrity, in its sole discretion, may make an attempt via email to warn the account owner prior or after the suspension.</p>

                            <h3>Discontinuation</h3>
                            <p>DevIntegrity reserves the right at any time to modify or discontinue, temporarily or permanently, your access with or without notice.</p>

                            <h3>Unacceptable Behaviour</h3>
                            <p>We do not consent to all behaviours carried out by our users and/or clients. Users may not assign or transfer their rights or obligations under these Terms in any way, without the written permission of the Owner.</p>

                            <h3>Common Provisions</h3>
                            <p>No Waiver - The Owner’s failure to assert any right or provision under these Terms shall not constitute a waiver of any such right or provision. No waiver shall be considered a further or continuing waiver of such term or any other term.</p>

                            <h3>Service Interruption</h3>
                            <p>To ensure the best possible service level, the Owner reserves the right to interrupt the Service for maintenance, system updates or any other changes, informing the Users appropriately.</p>

                            <h3>Service Reselling</h3>
                            <p>Users may not reproduce, duplicate, copy, sell, resell or exploit any portion of this Application and of its Service without the Owner’s express prior written permission, granted either directly or through a legitimate reselling program.</p>

                            <h3>Intellectual Property Rights</h3>
                            <p>Without prejudice to any more specific provision of these Terms, any intellectual property rights related to this Application are the exclusive property of the Owner or its licensors.</p>

                            <h3>Changes to these Terms</h3>
                            <p>The Owner reserves the right to amend or otherwise modify these Terms at any time, appropriately informing the User of these changes.</p>

                            <h3>Assignment of Contract</h3>
                            <p>The Owner reserves the right to transfer, assign, dispose of by novation, or subcontract any or all rights or obligations under these Terms, considering the User’s legitimate interests.</p>

                            <h3>Contacts</h3>
                            <p>All communications relating to the use of this Application must be sent using the contact information stated in this document.</p>

                            <h3>Definitions and Legal References</h3>
                            <p>This section provides the definitions of terms used within the document and any legal references applicable.</p>

                            <h3>Payments and Renewal</h3>
                            <h4>General Terms</h4>
                            <p>The User agrees to pay the fees applicable to the subscription plans selected. The User can terminate the subscription at any time. The subscription will renew automatically unless the User cancels it.</p>

                            <h4>Free Trial</h4>
                            <p>If a free trial is offered, the duration and terms will be specified, and the User must cancel the subscription before the trial period ends to avoid being charged.</p>

                            <h4>Chargebacks</h4>
                            <p>If the User initiates a chargeback for a transaction made, DevIntegrity reserves the right to suspend or terminate the User's account.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    name: 'TermsAndConditions',
}
</script>

<style scoped>
/* Add any custom styles here */
</style>
