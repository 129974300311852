const state = () => ({
    isLoading: false
  });
  
  const getters = {
    getIsLoading(state) {
      return state.isLoading;
    }
  };
  
  const mutations = {
    setIsLoading(state, value) {
      state.isLoading = value;
    }
  };
  
  const actions = {
    // Define any actions if needed
  };
  
  export default {
    state,
    getters,
    mutations,
    actions
  };
  