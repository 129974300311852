<template>
  <main class="isolate bg-gray-200 pt-0">
    <!-- Hero section -->
    <div class="relative isolate bg-gradient-to-r from-[#80caff] to-[#4f46e5]">
  <svg
    class="absolute inset-x-0 top-0  h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
    aria-hidden="true">
    <defs>
      <pattern id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84" width="200" height="200" x="50%" y="-1"
        patternUnits="userSpaceOnUse">
        <path d="M.5 200V.5H200" fill="none" />
      </pattern>
    </defs>
    <rect width="100%" height="100%" stroke-width="0" class="color-red-900"
      fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
  </svg>
  <div class="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
    aria-hidden="true">
    <div class="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
      style="clip-path: polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)" />
  </div>
  <div class="overflow-hidden">
    <div class="mx-auto max-w-7xl px-6 pb-32 pt-36 sm:pt-60 lg:px-8 lg:pt-32">
      <div class="mx-auto max-w-6xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
        <div class="w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
          <h1 class="text-4xl font-bold tracking-tight text-red-900 sm:text-6xl">Why Dev Integrity?</h1>
          <h3 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-6xl">A Reliable, Result Oriented &
            Relationship Driven Company to Be Your Technological Partner</h3>
          <p class="relative mt-6 text-lg leading-8 text-gray-900 sm:max-w-md lg:max-w-none">Most businesses
            function
            just fine with average software agencies, especially if the
            domains are well-structured and the problems are not unique. The processes take longer
            than necessary but they hardly get there.

            If, on the other side, you are planning to start a new project and you are not certain
            whether the business goals you have envisioned can be accomplished, your budget is
            tight or you need to accelerate at rapid pace. Then you need a trustworthy, relationship
            based and result oriented agency that can confidently say “We can do it for you”</p>
        </div>
        <div class="mt-14 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
          <div
            class="ml-auto w-44 flex-none space-y-8 pt-32 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
            <div class="relative">
              <img
                src="https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
                alt="" class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" />
              <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
            </div>
          </div>
          <div class="mr-auto w-44 flex-none space-y-8 sm:mr-0 sm:pt-52 lg:pt-36">
            <div class="relative">
              <img
                src="https://images.unsplash.com/photo-1485217988980-11786ced9454?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
                alt="" class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" />
              <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
            </div>
            <div class="relative">
              <img
                src="https://images.unsplash.com/photo-1559136555-9303baea8ebd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-x=.4&w=396&h=528&q=80"
                alt="" class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" />
              <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
            </div>
          </div>
          <div class="w-44 flex-none space-y-8 pt-32 sm:pt-0">
            <div class="relative">
              <img
                src="https://images.unsplash.com/photo-1670272504528-790c24957dda?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=left&w=400&h=528&q=80"
                alt="" class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" />
              <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
            </div>
            <div class="relative">
              <img
                src="https://images.unsplash.com/photo-1670272505284-8faba1c31f7d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&h=528&q=80"
                alt="" class="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg" />
              <div class="pointer-events-none absolute inset-0 rounded-xl ring-1 ring-inset ring-gray-900/10" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bg-gray-50 py-24 sm:py-32">
  <div class="mx-auto max-w-7xl px-6 lg:px-8">
    <div class="mx-auto max-w-5xl lg:mx-0 text-center">
      <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">What Makes Us Different?</h2>
      <p class="mt-6 text-lg leading-8 text-gray-500">
        Dev Integrity's story is about the clients we have served and the businesses we have helped. It’s about the trust we have built, the projects we have delivered, and the success we have achieved. Using our tech expertise and years of experience, we evolve digital transformation into digital acceleration. Our ultimate goal is to create lasting value throughout your digital transformation journey.
      </p>
    </div>
    <div class="mx-auto mt-16 max-w-5xl sm:mt-20 lg:mt-24 lg:max-w-none">
      <dl class="grid max-w-4xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
        <div v-for="feature in features" :key="feature.name" class="flex flex-col bg-white shadow-lg rounded-lg p-6 transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-2xl">
          <dt class="text-base font-semibold leading-7 text-cyan-600">
            <div class="flex mb-5">
              <img class="h-12 w-auto" :src="feature.href" alt="" />
            </div>
            {{ feature.name }}
          </dt>
          <dd class="mt-2 flex flex-auto flex-col text-base leading-7 text-gray-900">
            <p class="flex-auto">{{ feature.description }}</p>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</div>

    <div class="bg-white">
      <div class="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
        <div class="space-y-12">
          <div class="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
            <h2 class="text-3xl font-bold tracking-tight sm:text-4xl">Meet our Directors</h2>
            <p class="text-xl text-gray-500">We’ve got the knowledge and the experience to build the right solutions for
              your business when you need it. Solutions that will drive your business forward, freeing you to do the
              work
              of delighting your customers.</p>
          </div>
          <ul role="list"
            class="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:max-w-5xl lg:grid-cols-3">
            <li v-for="person in people" :key="person.name">
              <div class="space-y-6">
                <img class="mx-auto h-40 w-40 rounded-full xl:h-56 xl:w-56" :src="person.imageUrl" alt="" />
                <div class="space-y-2">
                  <div class="space-y-1 text-lg font-medium leading-6">
                    <h3>{{ person.name }}</h3>
                    <p class="text-indigo-600">{{ person.role }}</p>
                  </div>
                  <ul role="list" class="flex justify-center space-x-5">
                   
                    <li>
                      <a :href="person.linkedinUrl" class="text-gray-400 hover:text-gray-500">
                        <span class="sr-only">LinkedIn</span>
                        <svg class="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
                          <path fill-rule="evenodd"
                            d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                            clip-rule="evenodd" />
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

  </main>
</template>

<script setup>
const features = [
  {
    name: 'Communication Is The Key - We Communicate Clearly And Listen Carefully',
    description:
      'The one important trait that our customers love about us is our clear communication. We focus on explaining complex information to non-technical people to the point where they can envision their project at every step of development and feel confident about our approach. We also listen carefully to the uncertainties our clients have in their mind and drill down with questions to clarify the problems that need to be resolved.',
    href: '/assets/images/aboutus/Communication.png',
  },
  {
    name: 'Splitting A Large Complex Problem Into Solvable Small Elements',
    description:
      'We focus on untying tangled knots of a problem and find a way to a solution by splitting it into discrete elements and address each of those elements separately. By splitting a large, complex problem into solvable small elements, we bring clarity to the chaos. This method not only facilitates a more thorough understanding of the challenges at hand but also enables us to craft targeted solutions for each component.',
    href: '/assets/images/aboutus/comples_problems_solution.png',
  },
  {
    name: 'We Look Beyond The Problem At Hand',
    description:
      'We never take anything we see at the face value. We drive our decisions based on reliable data; we look beyond fixing the bugs to analyze if they are the symptoms of some larger problems. We focus on logical conclusions. Our decision-making process is anchored in the realm of reliable data and informed analysis. We do not settle for quick fixes; instead, we embark on a journey to decipher whether the visible issues are mere symptoms of more profound underlying problems.',
    href: '/assets/images/aboutus/look_beyound_problem.png',
  },
  {
    name: 'We Add Value At Every Step',
    description:
      'We immerse ourselves in the project to the point where we always know what business goal we are trying to achieve with the project at hand. We add value at every step by frequently asking questions, evaluating our directions and never let the project go off-track.',
    href: '/assets/images/aboutus/we_add_value_each_step.png',
  },
  {
    name: 'Methodical Thought Process - Abstract Thinking',
    description:
      'Abstract thinking is what sets us apart from the competition. We apply scientific methods, think through all the possible scenarios of an algorithm, challenge assumptions, and dig deep into the project- are all the aspects of our though process.',
    href: '/assets/images/aboutus/methodical_tought.png',
  },
  {
    name: 'Your Business Is Special, So Is Our Approach',
    description:
      'Your pain points may be similar to those of other businesses but your goals and objective are unique. That’s why our approach to build your application has a complete team of people dedicated to knowing about the business problems you require help solving.',
    href: '/assets/images/aboutus/business_is_special.png',
  }
]
const people = [
  {
    name: 'Aziz Ahmed',
    role: 'CEO & Director IT services',
    imageUrl: '/assets/images/directors/director1.jfif',
    twitterUrl: '#',
    linkedinUrl: 'https://www.linkedin.com/in/aziz-ahmed-990704129/',
  },
  {
    name: 'Yasir Hasnain',
    role: 'Director IT',
    imageUrl: '/assets/images/directors/director2.jfif',
    twitterUrl: '#',
    linkedinUrl: '#',
  },
  {
    name: 'Haseeb Ahmed',
    role: 'Director Human Resouces Solutions',
    imageUrl: '/assets/images/directors/director4.png',
    twitterUrl: '#',
    linkedinUrl: '#',
  }
]

// Function to dynamically import images
const importImage = async (path) => {
  const { default: src } = await import(`.${path}`);
  return src;
};

// Updating src dynamically for each product
features.forEach(async (product) => {
  product.src = await importImage(product.href);
});
</script>