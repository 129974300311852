import { request } from "@/mixins/request";
const actions = {
    async fetchDashboardOverview({ commit, getters }, payload) {
        const body = { ...getters.getFilters, ...payload };
        console.log("Fetching dashboard overview with payload:", body);
        try {
            const data = await request.methods.makeRequest("POST", "api/v1/get-dashboard-overview", body);
            console.log("Dashboard overview fetched successfully:", data);
            commit("setDashboardOverview", data.data);
        } catch (error) {
            console.error("Error fetching dashboard overview:", error);
        }
    }
};


export default actions;
