import { createWebHistory, createRouter } from "vue-router";
import Home from "../views/front/dashboard/index";
import Front from "./Front";
import Auth from "./Auth.js";

import VueRouteMiddleware from "vue-route-middleware";
import AuthMiddleware from "../middleware/Auth";

let routes = [
  
  {
    name: "/",
    path: "/",
    component: Home,
    meta: { title: "Dev Integrity" },
  },
  ...Auth,
  ...Front
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes: routes,
});
// dynamic title
router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  next();
});
router.beforeEach(VueRouteMiddleware({ AuthMiddleware }));
export default router;
