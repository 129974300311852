import { createStore } from "vuex";
import state from './state'; // Create a separate file for the state
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const dashboardModule = {
    state,
    mutations,
    actions,
    getters,
};

export default dashboardModule;
