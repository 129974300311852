<template>
   
    <nav class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6" aria-label="Pagination">
    <div class="hidden sm:block">
      <p class="text-sm text-gray-700">
        Showing
        {{ pagination.perPage }} on page {{ pagination.currentPage }} of total {{ pagination.total }}
      </p>
    </div>
    <div class="flex flex-1 justify-between sm:justify-end">
      <a v-if="prev" @click.prevent="paginate(this.pagination.currentPage-1)" class="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Previous</a>
      <a v-if="next" @click.prevent="paginate(this.pagination.currentPage+1)" class="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50">Next</a>
    </div>
  </nav>
</template>

<script>

export default 
{
    props: [
        "pagination",
        "fetchAction",
    ],
    data() {
        return {
            next: false,
            prev: false,
           
        };
    },
    watch: {
        pagination: function() 
        {
            this.setButtons();
        }
    },
    methods:
    {
        paginate(page)
        {   
            this.$store.dispatch(this.fetchAction, {
                page: page,
            });
            this.setButtons();
        },
        setButtons()
        {
            if(this.pagination)
            {
                // Next
                if( (this.pagination.currentPage * this.pagination.perPage) < this.pagination.total )
                {
                    this.next = true;
                }
                else
                {
                    this.next = false;
                }

                // Previous
                if( this.pagination.currentPage == 1 )
                {
                    this.prev = false;
                }
                else
                {
                    this.prev = true;
                }
            }
        }
    },
    mounted() {
        // console.log( "Pagination mounted!" );
        this.setButtons();
    },
};
</script>