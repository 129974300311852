const state = () => ({
  toasts: [
    // Example of initial state
    // {
    //   type: "error",
    //   message: "You have successfully warned!",
    //   position: "right_btm"
    // }
  ]
});

const getters = {
  getToasts(state) {
    return state.toasts;
  }
};

const mutations = {
  setToast(state, payload) {
    state.toasts.push(payload);
  },
  removeToast(state, id) {
    state.toasts = state.toasts.filter((toast) => toast.id != id);
  }
};

const actions = {
  createToast(context, payload) {
    var toastId = Math.floor(Math.random() * 999999);
    context.commit("setToast", { ...payload, id: toastId });
    setTimeout(() => {
      context.commit("removeToast", toastId);
    }, 9000);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
