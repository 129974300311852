
<template>

	<main>
		<div class="bg-gray-900 pt-10 sm:pt-16 lg:overflow-hidden lg:pt-8 lg:pb-14">
			<div class="mx-auto max-w-7xl lg:px-8">
				<div class="lg:grid lg:grid-cols-2 lg:gap-8">
					<div
						class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:flex lg:items-center lg:px-0 lg:text-left">
						<div class="lg:py-24">
							<a href="#"
								class="inline-flex items-center rounded-full bg-black p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base">
								<span
									class="rounded-full bg-gradient-to-r from-teal-500 to-cyan-600 px-3 py-0.5 text-sm font-semibold leading-5 text-white">Seacrch
									a job</span>
								<span class="ml-4 text-sm">Click here</span>
								<ChevronRightIcon class="ml-2 h-5 w-5 text-gray-500" aria-hidden="true" />
							</a>
							<h1
								class="mt-4 text-4xl font-bold tracking-tight text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
								<span class="block">The Leading Job Board</span>
								<span
									class="block bg-gradient-to-r from-teal-200 to-cyan-400 bg-clip-text pb-3 text-transparent sm:pb-5">For
									Blockchain And Cryptocurrency Jobs</span>
							</h1>
							<p class="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">Anim aute id
								magna aliqua ad ad non deserunt sunt. Qui irure qui Lorem cupidatat commodo.
								Elit sunt amet fugiat veniam occaecat fugiat.</p>
							<div class="mt-10 sm:mt-12">
								<form action="#" class="sm:mx-auto sm:max-w-xl lg:mx-0">
									<div class="sm:flex">
										<div class="min-w-0 flex-1">
											<label for="email" class="sr-only">Email address</label>
											<input id="email" type="email" placeholder="Enter your email"
												class="block w-full rounded-md border-0 px-4 py-3 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900" />
										</div>
										<div class="mt-3 sm:mt-0 sm:ml-3">
											<button type="submit"
												class="block w-full rounded-md bg-gradient-to-r from-teal-500 to-cyan-600 py-3 px-4 font-medium text-white shadow hover:from-teal-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-400 focus:ring-offset-2 focus:ring-offset-gray-900">Subscribe</button>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div class="mt-12 -mb-16 sm:-mb-48 lg:relative lg:m-0">
						<div class="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
							<!-- Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ -->
							<img class="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
								src="https://tailwindui.com/img/component-images/cloud-illustration-teal-cyan.svg"
								alt="" />
						</div>
					</div>
				</div>
			</div>
		</div>





		<main class="antialiased">
			<div id="find-a-job">
				<div class="lg:max-w-5xl mx-auto mt-20 md:mt-32 md:px-6 xl:px-0 search-ui">
					<div class="px-4 sm:px-6 md:px-0">
						<h2 class="text-2xl sm:text-3xl text-gray-900 font-bold">Search jobs</h2>
						<div class="mt-6 sm:mt-8">
							<div class="grid sm:grid-cols-2 gap-2 sm:gap-3">
								<div><span class="hidden sm:block text-gray-600 font-medium">Search for Job Title</span>
									<div id="searchbox" class="sm:mt-1">
										<div class="ais-SearchBox">
											<div class="ais-SearchBox-form">

												<input class="ais-SearchBox-input"
													v-model="this.$store.getters.getFilters.job_title"
													@keyup="this.setFilter('job_title', $event.target.value, action)"
													type="text" maxlength="512"><span class="ais-SearchBox-submit"><svg
														class="ais-SearchBox-submitIcon" width="10" height="10"
														viewBox="0 0 40 40">
														<path
															d="M26.804 29.01c-2.832 2.34-6.465 3.746-10.426 3.746C7.333 32.756 0 25.424 0 16.378 0 7.333 7.333 0 16.378 0c9.046 0 16.378 7.333 16.378 16.378 0 3.96-1.406 7.594-3.746 10.426l10.534 10.534c.607.607.61 1.59-.004 2.202-.61.61-1.597.61-2.202.004L26.804 29.01zm-10.426.627c7.323 0 13.26-5.936 13.26-13.26 0-7.32-5.937-13.257-13.26-13.257C9.056 3.12 3.12 9.056 3.12 16.378c0 7.323 5.936 13.26 13.258 13.26z">
														</path>
													</svg></span>

											</div>
										</div>
									</div>
								</div>
								<div><span class="hidden sm:block text-gray-600 font-medium">Search for Place and press
										Enter</span>
									<div id="aa-search-input" class="sm:mt-1">
										<div class="ais-SearchBox">
											<div class="ais-SearchBox-form"><span class="algolia-autocomplete"
													style="position: relative; display: inline-block; direction: ltr;"><input
														class="aa-SearchBox-input ais-SearchBox-input aa-input"
														type="text" v-model="this.$store.getters.getFilters.company_hq"
														@keyup.enter="this.setFilter('company_hq', $event.target.value, action)"
														style="position: relative; vertical-align: top;">
													<pre aria-hidden="true"
														style="position: absolute; visibility: hidden; white-space: pre; font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, &quot;segoe ui&quot;, Roboto, &quot;helvetica neue&quot;, Arial, &quot;noto sans&quot;, sans-serif, &quot;apple color emoji&quot;, &quot;segoe ui emoji&quot;, &quot;segoe ui symbol&quot;, &quot;noto color emoji&quot;; font-size: 18px; font-style: normal; font-variant: normal; font-weight: 400; word-spacing: 0px; letter-spacing: normal; text-indent: 0px; text-rendering: auto; text-transform: none;"></pre>
													<span class="aa-dropdown-menu" role="listbox"
														id="algolia-autocomplete-listbox-0"
														style="position: absolute; top: 100%; z-index: 100; display: none; left: 0px; right: auto;">
														<div class="aa-dataset-1"></div>
														<div class="aa-dataset-2"></div>
														<div class="aa-dataset-3"></div>
													</span>
												</span>
												<span class="ais-SearchBox-submit"><svg class="ais-SearchBox-submitIcon"
														height="10" width="10" viewBox="0 0 14 20">
														<path
															d="M7 0C3.13.0.0 3.13.0 7c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5C5.62 9.5 4.5 8.38 4.5 7S5.62 4.5 7 4.5 9.5 5.62 9.5 7 8.38 9.5 7 9.5z">
														</path>
													</svg></span>

											</div>
										</div>
									</div>
								</div>

							</div>
						</div>
						<nav class="mt-3">
							<ul
								class="flex flex-wrap lg:flex-nowrap items-center lg:justify-between text-gray-600 -mx-1.5 lg:-mx-0">

								<li v-for="(jobCategory, index) in this.$store.getters.getJobsCreate.job_category"
									:key="index" class="inline-flex mx-1.5 my-1 lg:m-0"><a
										class="text-purple hover:text-purple transition-colors" :href="'/category/' +jobCategory._id
										">{{ jobCategory.name
										}}</a></li>
								<li aria-hidden="true">·</li>

								<li aria-hidden="true">·</li>
								<li class="inline-flex mx-1.5 my-1 lg:m-0"><a
										class="hover:text-purple transition-colors" href="/">View All</a></li>
							</ul>
						</nav>
					</div>
					<div class="flex justify-between text-gray-700 mt-16 md:mt-24 px-4 sm:px-6 md:px-0">
						<div id="stats">
							<div class="ais-Stats"><span class="ais-Stats-text text-base text-gray-700">54
									blockchain jobs</span></div>
						</div><a class="flex items-center hover:text-purple transition-colors" href="/newsletter/"
							target="_blank" rel="noopener"><span aria-hidden="true"><svg class="h-5 w-5 mr-1 sm:mr-2"
									fill="none" stroke="currentcolor" height="20" width="20" viewBox="0 0 24 24">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
										d="M15 17h5l-1.405-1.405A2.032 2.032.0 0118 14.158V11a6.002 6.002.0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9">
									</path>
								</svg></span><span class="inline sm:hidden">Job alerts</span>
							<span class="hidden sm:inline">Subscribe to job alerts</span></a>
					</div>

					<div id="hits" class="mt-0">
						<div>
							<div class="ais-Hits">
								<ol v-if="this.$store.getters.getJobs.length" class="ais-Hits-list">

									<li v-for="(job, index) in this.$store.getters.getJobs" :key="index" :value="job.id"
										class="ais-Hits-item">
										<div class="grid text-sm text-gray-600 bg-white border-t border-gray-200 p-4 sm:p-6"
											style="grid-template-columns: auto minmax(0, 1fr)">

											<img class="h-8 sm:h-10 md:h-11 w-8 sm:w-10 md:w-11 bg-white border border-gray-100 lazyload sm:mr-4 rounded-full"
												:src=" baseURL + '/' + job.company_logo_link " data-sizes="auto"
												height="44" width="44" alt="Upshot logo">




											<div class="col-start-1 sm:col-start-2 row-start-2 sm:row-start-1">
												<h2
													class="text-lg sm:text-xl md:text-2xl text-gray-800 font-bold mt-2 sm:mt-0">
													<a class="hover:text-purple transition-colors"
														:href="'/job-detail/' +job._id ">
														{{ job.job_title }} </a>
												</h2>


												<h3 class="text-base md:text-lg text-gray-800"> <a
														class="hover:text-purple transition-colors"
														:href=" job.company_website_url "> {{ job.company_name }} </a>
												</h3>

												<h5 v-if="job.job_description.length < 400"> {{ job.job_description }}
												</h5>
												<h5 v-else>{{ job.job_description.substring(0,400)+"..." }}</h5>

											</div>
											<div class="col-start-1 sm:col-start-2 col-end-2 sm:col-end-4">
												<div class="flex flex-row flex-wrap mt-3 space-x-1 sm:space-x-2">
													<ul class="leading-relaxed sm:leading-normal">
														<li class="inline">
															<h4 class="inline"><a
																	class="hover:text-purple transition-colors"
																	href="#">{{ job.company_hq }}</a> </h4>
														</li>
													</ul> <span aria-hidden="true">·</span>
													<h4 class="leading-relaxed sm:leading-normal"> <a
															class="hover:text-purple transition-colors" href="/design/">
															{{job.job_category.name}} </a> </h4> <span
														aria-hidden="true">·</span>
													<ul class="leading-relaxed sm:leading-normal">
														<li class="inline-block">
															<h4 class="inline"><a
																	class="hover:text-purple transition-colors"
																	href="/full-time/">Full-Time</a></h4>
														</li>
													</ul>
												</div>
												<ul
													class="flex flex-wrap -mb-1 sm:-mb-0.5 mt-1 md:mt-2 -mx-1 sm:-mx-0.5">
													<li v-for="(job_skill, index) in job.job_skill" :key="index"
														:value="job_skill.id"
														class="border border-gray-300 m-1 sm:m-0.5 rounded-md group hover:border-purple transition-colors">
														<a class="block group-hover:text-purple p-1" href="#">
															{{ job_skill.name }} </a>
													</li>


												</ul>
											</div>
											<div
												class="col-start-3 row-start-1 flex items-baseline justify-end self-center sm:self-auto">
												<div class="inline-block leading-loose"> <time
														datetime="2022-07-13T08:00:00"></time> <span> {{
														vpDate(job.createdAt) }} </span>
												</div>
											</div>
										</div>
									</li>

								</ol>
								<ol v-else>
									<div id="hits" class="mt-0">
										<div>
											<div class="ais-Hits ais-Hits--empty">
												<div class="max-w-none prose md:prose-lg mt-12 px-4 sm:px-6 md:px-0">
													<p class="md:text-lg font-bold">No open results match your search.
													</p>
													<p>Suggestions:</p>
													<ul>
														<li>Check your spelling</li>
														<li>Try using fewer, different, or more general keywords</li>
														
													</ul>
													
												</div>
											</div>
										</div>
									</div>
								</ol>


							</div>
						</div>
					</div>
					<Pagination :pagination="this.$store.getters.getJobs.pagination" :fetchAction="action" />
				</div>

			</div>



		</main>
		<!-- Blog section -->
		<div class="relative bg-gray-50 py-16 sm:py-24 lg:py-32">
			<div class="relative">
				<div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
					<h2 class="text-lg font-semibold text-cyan-600">Learn</h2>
					<p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Helpful
						Resources</p>
					<p class="mx-auto mt-5 max-w-prose text-xl text-gray-500">Phasellus lorem quam molestie id
						quisque diam aenean nulla in. Accumsan in quis quis nunc, ullamcorper malesuada.
						Eleifend condimentum id viverra nulla.</p>
				</div>
				<div
					class="mx-auto mt-12 grid max-w-md gap-8 px-4 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:grid-cols-3 lg:px-8">
					<div v-for="post in blogPosts" :key="post.id"
						class="flex flex-col overflow-hidden rounded-lg shadow-lg">
						<div class="flex-shrink-0">
							<img class="h-48 w-full object-cover" :src="post.imageUrl" alt="" />
						</div>
						<div class="flex flex-1 flex-col justify-between bg-white p-6">
							<div class="flex-1">
								<p class="text-sm font-medium text-cyan-600">
									<a :href="post.category.href" class="hover:underline">{{ post.category.name
									}}</a>
								</p>
								<a :href="post.href" class="mt-2 block">
									<p class="text-xl font-semibold text-gray-900">{{ post.title }}</p>
									<p class="mt-3 text-base text-gray-500">{{ post.preview }}</p>
								</a>
							</div>
							<div class="mt-6 flex items-center">
								<div class="flex-shrink-0">
									<a :href="post.author.href">
										<img class="h-10 w-10 rounded-full" :src="post.author.imageUrl"
											:alt="post.author.name" />
									</a>
								</div>
								<div class="ml-3">
									<p class="text-sm font-medium text-gray-900">
										<a :href="post.author.href" class="hover:underline">{{ post.author.name
										}}</a>
									</p>
									<div class="flex space-x-1 text-sm text-gray-500">
										<time :datetime="post.datetime">{{ post.date }}</time>
										<span aria-hidden="true">&middot;</span>
										<span>{{ post.readingLength }} read</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<!-- CTA Section -->
		<div class="relative bg-gray-900">
			<div class="relative h-56 bg-indigo-600 sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
				<img class="h-full w-full object-cover"
					src="https://images.unsplash.com/photo-1525130413817-d45c1d127c42?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1920&q=60&sat=-100"
					alt="" />
				<div aria-hidden="true"
					class="absolute inset-0 bg-gradient-to-r from-teal-500 to-cyan-600 mix-blend-multiply" />
			</div>
			<div class="relative mx-auto max-w-md px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
				<div class="md:ml-auto md:w-1/2 md:pl-10">
					<h2 class="text-lg font-semibold text-gray-300">Award winning support</h2>
					<p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">We’re here to help
					</p>
					<p class="mt-3 text-lg text-gray-300">Lorem ipsum dolor sit amet, consectetur adipiscing
						elit. Et, egestas tempus tellus etiam sed. Quam a scelerisque amet ullamcorper eu enim
						et fermentum, augue. Aliquet amet volutpat quisque ut interdum tincidunt duis.</p>
					<div class="mt-8">
						<div class="inline-flex rounded-md shadow">
							<a href="#"
								class="inline-flex items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-gray-900 hover:bg-gray-50">
								Visit the help center
								<ArrowTopRightOnSquareIcon class="-mr-1 ml-3 h-5 w-5 text-gray-400"
									aria-hidden="true" />
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>


</template>

<script>
export default {
	data() {
		return {
			action: "fetchJobs",
			baseURL: process.env.VUE_APP_API_URL,
		};
	},
	methods: {

		async getFilteredData() {
			await this.$store.dispatch(this.action, {
				body: {},
				options: {
					loader: true,
					tableSkeleton: true,
				},
			});
		},
		async fetchJobsCreate() {
			await this.$store.dispatch('fetchJobsCreate', {
				body: {},
			});
		},
	},
	created() {
		this.resetFilters();
		this.getFilteredData();
		this.fetchJobsCreate();
	},
};
</script>


<script setup>
import { ArrowTopRightOnSquareIcon, ChevronRightIcon } from '@heroicons/vue/20/solid'

const blogPosts = [
	{
		id: 1,
		title: 'Boost your conversion rate',
		href: '/blog-detail',
		date: 'Mar 16, 2020',
		datetime: '2020-03-16',
		category: { name: 'Article', href: '#' },
		imageUrl:
			'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80',
		preview:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto accusantium praesentium eius, ut atque fuga culpa, similique sequi cum eos quis dolorum.',
		author: {
			name: 'Roel Aufderehar',
			imageUrl:
				'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
			href: '#',
		},
		readingLength: '6 min',
	},
	{
		id: 2,
		title: 'How to use search engine optimization to drive sales',
		href: '/blog-detail',
		date: 'Mar 10, 2020',
		datetime: '2020-03-10',
		category: { name: 'Video', href: '#' },
		imageUrl:
			'https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80',
		preview:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facilis asperiores porro quaerat doloribus, eveniet dolore. Adipisci tempora aut inventore optio animi., tempore temporibus quo laudantium.',
		author: {
			name: 'Brenna Goyette',
			imageUrl:
				'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
			href: '#',
		},
		readingLength: '4 min',
	},
	{
		id: 3,
		title: 'Improve your customer experience',
		href: '/blog-detail',
		date: 'Feb 12, 2020',
		datetime: '2020-02-12',
		category: { name: 'Case Study', href: '#' },
		imageUrl:
			'https://images.unsplash.com/photo-1492724441997-5dc865305da7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80',
		preview:
			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Sint harum rerum voluptatem quo recusandae magni placeat saepe molestiae, sed excepturi cumque corporis perferendis hic.',
		author: {
			name: 'Daniela Metz',
			imageUrl:
				'https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
			href: '#',
		},
		readingLength: '11 min',
	},
]
</script>