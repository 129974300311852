<template>
    <main>
       
        <div class="relative bg-gray-100 py-16 sm:py-24 lg:py-32">
			<div class="relative">
				<div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
					<h2 class="text-lg font-semibold text-cyan-600">Learn</h2>
					<p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Helpful
						Resources</p>
					<p class="mx-auto mt-5 max-w-prose text-xl text-gray-500">Our Blog is Written by Experts. Explore the depths of IT expertise on our blog, where our team of seasoned professionals unravels the intricate threads of the tech world. </p>
				</div>
				<div
					class="mx-auto mt-12 grid max-w-md gap-8 px-4 sm:max-w-lg sm:px-6 lg:max-w-7xl lg:grid-cols-3 lg:px-8">
					<div v-for="post in blogPosts" :key="post.id"
						class="flex flex-col overflow-hidden rounded-lg shadow-lg">
						<div class="flex-shrink-0">
							<img class="h-48 w-full object-cover" :src="post.imageUrl" alt="" />
						</div>
						<div class="flex flex-1 flex-col justify-between bg-white p-6">
							<div class="flex-1">
								<p class="text-sm font-medium text-cyan-600">
									<a :href="post.category.href" class="hover:underline">{{ post.category.name
									}}</a>
								</p>
								<a :href="post.href" class="mt-2 block">
									<p class="text-xl font-semibold text-gray-900">{{ post.title }}</p>
									<p class="mt-3 text-base text-gray-500">{{ post.preview }}</p>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    </main>
</template>
  
<script setup>
const blogPosts = [
	{
		id: 1,
		title: '10 Best Plugin For WooCommerce',
		href: '/blog-detail/wooCommerce',
		date: 'Mar 16, 2020',
		datetime: '2020-03-16',
		category: { name: 'Article' },
        imageUrl: '/assets/images/blogs/10_Best_Plugin_For_WooCommerce.png',
		preview:
			'WordPress is now a dominant Ecommerce platform everywhere. And, this dominance wasn’t possible without best plugins for WooCommerce. Started in 2003, WordPress have had a tough time in reaching to…',
	
		readingLength: '6 min',
	},
	{
		id: 2,
		title: '10 Compelling Reasons For Custom Web Application Development',
        href: '/blog-detail/customWebDevelopment',
		date: 'Mar 10, 2020',
		datetime: '2020-03-10',
		category: { name: 'Article' },
        imageUrl: '/assets/images/blogs/10_Compelling_Reasons_For_Custom_Web_Application_Development.png',
		preview:
			'There is no denying that web applications have become a crucial instrument for business. The purpose of web app development is to streamline things for businesses as well ....',
		readingLength: '4 min',
	},
	{
		id: 3,
		title: '10 Must-Have CMS Features For Your Business In 2022',
        href: '/blog-detail/customWebApplicationDevelopment',
		date: 'Feb 12, 2020',
		datetime: '2020-02-12',
		category: { name: 'Case Study', href: '#' },
		imageUrl: '/assets/images/blogs/Have_CMS_Features_For_Your_Business_In_2022.jpg',
		preview:
			'A content management system (CMS) is a software that enables a user to create and control the site’s content, design, and functionality without touching ....',
		
		readingLength: '11 min',
	},
	{
		id: 4,
		title: '3 Steps For A Successful Cloud Migration Process',
        href: '/blog-detail/cloudMigrationProcess',
		date: 'Feb 12, 2020',
		datetime: '2020-02-12',
		category: { name: 'Case Study'},
		imageUrl: '/assets/images/blogs/3_Steps_For_A_Successful_Cloud_Migration_Process.jpg',
		preview:
			'Nowadays, the success and failure of all businesses rely on technological innovation. Many firms have adapted to technical business, enabling them to grow and expand more technologically. Businesses are confronted....',
		
		readingLength: '11 min',
	},
	{
		id: 5,
		title: '3 Tips To Market A Master Web Development Service',
        href: '/blog-detail/masterWebDevelopmentService',
		date: 'Feb 12, 2020',
		datetime: '2020-02-12',
		category: { name: 'Case Study' },
		imageUrl: '/assets/images/blogs/3_Tips_To_Market_A_Master_Web_Development_Service.png',
		preview:
			'Ever wondered, why don’t your web development service isn’t impactful; even though, you’ve most talented workforce and your pricing packages are fully budget-friendly. Confused and anxious, you are wandering for…',
		
		readingLength: '11 min',
	},
	{
		id: 6,
		title: '4 Benefits Of Mobile App Development In HealthCare Industry',
        href: '/blog-detail/healthCareIndustry',
		date: 'Feb 12, 2020',
		datetime: '2020-02-12',
		category: { name: 'Case Study' },
		imageUrl: '/assets/images/blogs/4_Benefits_Of_Mobile_App_Development_In_HealthCare_Industry.png',
		preview:
			'Quite recently, mobile app development has revolutionized the ways we use to interact on digital landscape. With a plethora of smartphone users across the globe, mobile apps have changed ....',
		
		readingLength: '11 min',
	},
	{
		id: 7,
		title: 'Headless vs. Traditional CMS: Digital Evolution & Content Velocity',
        href: '/blog-detail/contentVelocity',
		date: 'Feb 12, 2020',
		datetime: '2020-02-12',
		category: { name: 'Case Study' },
		imageUrl: '/assets/images/blogs/headless-vs-traditional-cms-digital-evolution-content-velocity.jpg',
		preview:
			'In today’s digital age, content management has become essential to any business’s marketing strategy. Companies must create, manage, and distribute ....',
		
		readingLength: '11 min',
	},
	{
		id: 7,
		title: 'How Chat GPT is Revolutionizing Various Industries',
        href: '/blog-detail/chatGpt',
		date: 'Feb 12, 2020',
		datetime: '2020-02-12',
		category: { name: 'Case Study' },
		imageUrl: '/assets/images/blogs/how-chat-gpt-is-revolutionizing-various-industries.png',
		preview:
			'Chat GPT, or Generative Pre-trained Transformer 3 & 4, are state-of-the-art natural language processing (NLP) models developed by OpenAI. It ....',
		
		readingLength: '11 min',
	},
	{
		id: 8,
		title: 'Reasons Why Your Business Needs CMS',
        href: '/blog-detail/businessNeedsCMS',
		date: 'Feb 12, 2020',
		datetime: '2020-02-12',
		category: { name: 'Case Study' },
		imageUrl: '/assets/images/blogs/bannerImg2-1024x379-1.png',
		preview:
			'Regardless of what size or type of business you have, a relevant website that looks professional, reflects your brand, and ....',
		
		readingLength: '11 min',
	},
]
</script>