<template>
    <main>
        <div class="relative overflow-hidden bg-gray-50 py-16">
            <div class="lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
                <div class="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
                    <svg class="absolute top-12 left-full translate-x-32 transform" width="404" height="384" fill="none" viewBox="0 0 404 384">
                        <defs>
                            <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                            </pattern>
                        </defs>
                        <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
                    </svg>
                </div>
            </div>
            <div class="relative px-2 sm:px-2 lg:px-2">
                <div class="mx-auto max-w-6xl text-lg">
                    <h1>
                        <span class="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                            GDPR Compliance
                        </span>
                    </h1>
                </div>
                <div class="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500">
                    <div class="elementor-element elementor-element-3b3d0baf color-scheme-inherit text-left elementor-widget elementor-widget-text-editor"
                        data-id="3b3d0baf" data-element_type="widget" data-widget_type="text-editor.default">
                        <div class="elementor-widget-container text-black">
                            <!-- Insert your GDPR content here -->
                            <h2>General Data Protection Regulation (GDPR)</h2>
                            <p>At DevIntegrity, we are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner.</p>
                            <p>This policy explains how we collect, use, disclose, and safeguard your information when you visit our website, including any other media form, media channel, mobile website, or mobile application related or connected thereto.</p>
                            
                            <h3>Information We Collect</h3>
                            <p>We may collect information about you in a variety of ways, including:</p>
                            <ul>
                                <li><strong>Personal Data:</strong> Personally identifiable information, such as your name, shipping address, email address, and telephone number that you voluntarily give to us when you register with the site or when you choose to participate in various activities related to the site.</li>
                                <li><strong>Derivative Data:</strong> Information our servers automatically collect when you access the site, such as your IP address, your browser type, your operating system, your access times, and the pages you have viewed directly before and after accessing the site.</li>
                            </ul>

                            <h3>Use of Your Information</h3>
                            <p>Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the site to:</p>
                            <ul>
                                <li>Create and manage your account.</li>
                                <li>Send you a confirmation email when you register.</li>
                                <li>Provide you with customer support.</li>
                                <li>Send you marketing and promotional communications.</li>
                                <li>Perform other business activities as needed.</li>
                            </ul>

                            <h3>Disclosure of Your Information</h3>
                            <p>We may share information we have collected about you in certain situations. Your information may be disclosed as follows:</p>
                            <ul>
                                <li><strong>By Law or to Protect Rights:</strong> If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.</li>
                                <li><strong>Third-Party Service Providers:</strong> We may share your information with third parties that perform services for us or on our behalf, including payment processing, data analysis, email delivery, hosting services, customer service, and marketing assistance.</li>
                            </ul>

                            <h3>Third-Party Websites</h3>
                            <p>The site may contain links to third-party websites and applications of interest, including advertisements and external services that are not affiliated with us. Once you have used these links to leave the site, any information you provide to these third parties is not covered by this privacy policy, and we cannot guarantee the safety and privacy of your information.</p>

                            <h3>Security of Your Information</h3>
                            <p>We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that no method of transmission over the Internet or method of electronic storage is 100% secure, and we cannot guarantee its absolute security.</p>

                            <h3>Policy for Children</h3>
                            <p>We do not knowingly solicit information from or market to children under the age of 13. If we learn that we have collected personal information from a child under age 13 without verification of parental consent, we will delete that information as quickly as possible.</p>

                            <h3>Options Regarding Your Information</h3>
                            <p>You may at any time review or change the information in your account or terminate your account by:</p>
                            <ul>
                                <li>Logging into your account settings and updating your account.</li>
                                <li>Contacting us using the contact information provided below.</li>
                            </ul>

                            <h3>Contact Us</h3>
                            <p>If you have questions or comments about this Privacy Policy, please contact us at:</p>
                            <p>Email: <a href="mailto:info@devintegrity.com" class="text-indigo-600">info@devintegrity.com</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    name: 'TermsAndConditions',
}
</script>

<style scoped>
/* Add any custom styles here */
</style>
