<template>

    <!-- Site Loader -->
    <Loading v-if="this.$store?.getters?.getIsLoading" />

    <!-- Notification Toasts -->
    <Toasts />


    <!-- Show Authentication APP -->
    <MainApp />

</template>
<script>

    // Import apps
    import Loading from './views/components/Loading.vue';
    import Toasts from './views/components/Toasts.vue';
    import MainApp from './views/layout/Master.vue';

    export default {
        name: 'App',
        data() {
           
            return {
                
            };
        },
        components: {
            Loading,
            MainApp,
            Toasts
        },
        created() {
              console.log("app.vue",this.$store)
        },
    }
</script>

<style src="./assets/tailwind.css"></style>