// import { request } from "@/mixins/request";

const state = () => ({
    filters: {
      page: 1,
      perPage: process.env.VUE_APP_PER_PAGE ?? 10,
      search: null,
      company_hq: null,
      job_title: null,
      startDate: null,
      endDate: null,
    }
  });
  
  const getters = {
    getFilters(state) {
      return state.filters;
    }
  };
  
  const mutations = {
    setFilters(state, payload) {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
    resetFilters(state, payload) {
      state.filters = {
        page: 1,
        perPage: process.env.VUE_APP_PER_PAGE ?? 10,
        search: null,
        ...payload,
      };
    }
  };
  
  const actions = {
    // Define any actions if needed
  };
  
  export default {
    state,
    getters,
    mutations,
    actions
  };
  