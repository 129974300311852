<template>
    <div class="bg-white">
        <div class="relative overflow-hidden">
            <Header />


            <router-view></router-view>


            <Footer />

        </div>
    </div>
</template>


<script>

// Import Layout
import Header from './common/Header';
import Footer from './common/Footer';

export default {

    components: {
        Header,
        Footer,
    },
    data() {
        return {
        };
    },
    mounted() {
        
        console.log("master.vue",this.$store)
        console.log("main app");
    },
};
</script>