<template>
  <div class="bg-gray-900">
    <main>
      <!-- Hero section -->
      <div class="relative isolate overflow-hidden">
        <svg
          class="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true">
          <defs>
            <pattern id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc" width="200" height="200" x="50%" y="-1"
              patternUnits="userSpaceOnUse">
              <path d="M.5 200V.5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y="-1" class="overflow-visible fill-gray-800/20">
            <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
              stroke-width="0" />
          </svg>
          <rect width="100%" height="100%" stroke-width="0" fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)" />
        </svg>
        <div
          class="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
          aria-hidden="true">
          <div class="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
            style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)" />
        </div>
        <div class="mx-auto max-w-7xl px-1 pt-10 sm:pb-10 lg:flex lg:px-8 lg:pt-40">
          <div class="mx-auto px-2 max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">

            <h1 class="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl">Mobile
              Application Development Services</h1>
            <p class="mt-6 text-lg leading-8 text-gray-300">Looking for a reliable mobile app development partner who
              can
              dive deep into your business ecosystem? Who can help you in exploring the market and understanding your
              needs, requirements, and capabilities? Who can create a truly custom strategy and harness the full
              potential
              of mobile technology for your company? That where we come in!

              Possessing a decade-long experience in custom mobile application development spanning a wide range of
              industries from retail to healthcare and EdTech, we create seamless mobile applications on various
              platforms
              that are tailored to your goals and expectations. Our approach ensures that your mobile application
              reflects your brand’s vision, meets your users' needs, and helps you stay ahead of the competition.

            </p>

          </div>
          <div
            class="mx-auto mt-25 flex max-w-1xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-20 lg:max-w-none lg:flex-none xl:ml-32">
            <div class="mt-25 max-w-2xl flex-none sm:max-w-2xl lg:max-w-2xl">
              <img src="/assets/images/aboutus/Android-App-Development-Dvintegrity.png" alt="App screenshot"
                width="732" class="w-[95%] h-[85%] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10" />
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 py-24 sm:py-32">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <div class="mx-auto max-w-5xl lg:mx-0 text-center">
            <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Our Mobile App Development Services
            </h2>
            <p class="mt-6 text-lg leading-8 text-gray-500">
              We don’t just create apps; our services cover the entire app development lifecycle, from concept and
              design
              to prototyping and UX, delivery, and distribution. Our apps solve real-world business problems, provide
              new
              and innovative ways of engaging with customers, and enable better working practices.</p>

          </div>
          <div class="mx-auto mt-16 max-w-5xl sm:mt-20 lg:mt-24 lg:max-w-none">
            <dl class="grid max-w-4xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
              <div v-for="feature in features" :key="feature.name"
                class="flex flex-col bg-white shadow-lg rounded-lg p-6 transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-2xl">
                <dt class="text-base font-semibold leading-7 text-cyan-600">
                  <div class="flex mb-5">
                    <img class="h-12 w-auto" :src="feature.href" alt="" />
                  </div>
                  {{ feature.name }}
                </dt>
                <dd class="mt-2 flex flex-auto flex-col text-base leading-7 text-gray-900">
                  <p class="flex-auto">{{ feature.description }}</p>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

      <div class="bg-white py-24 sm:py-32">
        <div class="mx-auto max-w-7xl md:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-1 lg:items-start">
            <div class="px-6 lg:px-0 lg:pr-4 lg:pt-4">
              <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-4xl">
                <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Mobile Application
                  Development & Support Cycle</p>
                <p class="mt-6 text-lg leading-8 text-gray-600">Our mobile application development cycle ensures the
                  creation of high-quality apps, focusing on performance, user experience, and continuous improvement to
                  meet the growing needs of your business.</p>

                <!-- Timeline -->
                <div class="relative mt-10">

                  <!-- Planning & Strategy -->
                  <div class="relative mb-10 pl-10">
                    <span
                      class="absolute left-0 top-0 h-10 w-10 bg-cyan-600 rounded-full flex items-center justify-center text-white text-lg font-bold">1</span>
                    <div class="ml-4 pl-6">
                      <h2 class="text-2xl font-bold tracking-tight text-black sm:text-4xl">Planning & Strategy</h2>
                      <p class="mt-2 text-lg text-gray-600">We work closely with you to define app objectives, target
                        audience, and key features to build a clear roadmap for successful app development.</p>
                      <ul class="mt-4 list-disc list-inside">
                        <li>Understand user needs and business goals</li>
                        <li>Create detailed feature sets and wireframes</li>
                        <li>Establish timelines and development cycles</li>
                      </ul>
                    </div>
                  </div>

                  <!-- Design & Prototyping -->
                  <div class="relative mb-10 pl-10">
                    <span
                      class="absolute left-0 top-0 h-10 w-10 bg-cyan-600 rounded-full flex items-center justify-center text-white text-lg font-bold">2</span>
                    <div class="ml-4 pl-6">
                      <h2 class="text-2xl font-bold tracking-tight text-black sm:text-4xl">Design & Prototyping</h2>
                      <p class="mt-2 text-lg text-gray-600">We design intuitive and visually appealing interfaces that
                        enhance user engagement and provide prototypes for your review before moving into development.
                      </p>
                      <ul class="mt-4 list-disc list-inside">
                        <li>Create user-friendly UI/UX designs</li>
                        <li>Develop interactive prototypes for testing</li>
                        <li>Ensure seamless navigation and user experience</li>
                      </ul>
                    </div>
                  </div>

                  <!-- Development & Testing -->
                  <div class="relative mb-10 pl-10">
                    <span
                      class="absolute left-0 top-0 h-10 w-10 bg-cyan-600 rounded-full flex items-center justify-center text-white text-lg font-bold">3</span>
                    <div class="ml-4 pl-6">
                      <h2 class="text-2xl font-bold tracking-tight text-black sm:text-4xl">Development & Testing</h2>
                      <p class="mt-2 text-lg text-gray-600">Our expert developers use cutting-edge technologies to build
                        and rigorously test your mobile app, ensuring it meets performance standards across devices.</p>
                      <ul class="mt-4 list-disc list-inside">
                        <li>Write clean, scalable code</li>
                        <li>Perform extensive unit and system testing</li>
                        <li>Ensure compatibility across platforms (iOS, Android)</li>
                      </ul>
                    </div>
                  </div>

                  <!-- Deployment & Support -->
                  <div class="relative mb-10 pl-10">
                    <span
                      class="absolute left-0 top-0 h-10 w-10 bg-cyan-600 rounded-full flex items-center justify-center text-white text-lg font-bold">4</span>
                    <div class="ml-4 pl-6">
                      <h2 class="text-2xl font-bold tracking-tight text-black sm:text-4xl">Deployment & Support</h2>
                      <p class="mt-2 text-lg text-gray-600">We ensure smooth deployment to app stores and provide
                        ongoing support and updates to keep your app functional and up-to-date with the latest trends.
                      </p>
                      <ul class="mt-4 list-disc list-inside">
                        <li>Deploy apps to Google Play and Apple App Store</li>
                        <li>Provide continuous updates and feature enhancements</li>
                        <li>Offer 24/7 support and bug fixing</li>
                      </ul>
                    </div>
                  </div>

                </div>
                <!-- End Timeline -->
              </div>
            </div>
          </div>
        </div>
      </div>



      <!-- CTA section -->
      <div class="relative isolate mt-10 px-6 py-32 sm:mt-56 sm:py-40 lg:px-8">
        <svg
          class="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
          aria-hidden="true">
          <defs>
            <pattern id="1d4240dd-898f-445f-932d-e2872fd12de3" width="200" height="200" x="50%" y="0"
              patternUnits="userSpaceOnUse">
              <path d="M.5 200V.5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y="0" class="overflow-visible fill-gray-800/20">
            <path d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
              stroke-width="0" />
          </svg>
          <rect width="100%" height="100%" stroke-width="0" fill="url(#1d4240dd-898f-445f-932d-e2872fd12de3)" />
        </svg>
        <div class="absolute inset-x-0 top-10 -z-10 flex transform-gpu justify-center overflow-hidden blur-3xl"
          aria-hidden="true">
          <div class="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
            style="clip-path: polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)" />
        </div>
        <div class="mx-auto max-w-2xl text-center">
          <h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl">Dev Integrity - Custom Software
            Development & Human Resources Company Delivering at Scale</h2>
          <p class="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-300">Dev Integrity helps businesses transform and
            gain a competitive edge by delivering superior custom-made applications while prioritizing long-term
            relationships through supporting, maintaining, and sustaining their mission-critical applications.</p>
          <div class="mt-10 flex items-center justify-center gap-x-6">
            <a href="/services"
              class="rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">Get
              started</a>
            <a href="/services" class="text-sm font-semibold leading-6 text-white">Learn more <span
                aria-hidden="true">→</span></a>
          </div>
        </div>
      </div>
    </main>

  </div>
</template>

<script setup>

import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  Cog6ToothIcon,
  FingerPrintIcon,
  LockClosedIcon,
  ServerIcon,
} from '@heroicons/vue/20/solid'


const features = [
  {
    name: 'Mobile Enablement',
    description:
      'We create result driven application that adds exceptional value to your business. With our mobile application development services, we help our customers to meet demanding needs and accelerate overall productivity by focusing on frictionless end-user experience.',
    href: '/assets/images/aboutus/booking-ma.jpg',
  },
  {
    name: 'Cross Platform Mobile App Development',
    description:
      'With our extensive experience in cross-platform app development, we help businesses to reach a much wider audience and meet user demands through high-performance mobile apps that work well across various devices and platforms.',
    href: '/assets/images/aboutus/mobile-app-ma.jpg',
  },
  {
    name: 'IOS Mobile App Development',
    description:
      'We build scalable, secure, and high-performance iOS apps for the complete apple product range. We adhere to strict apple’s human interface guidelines and best practices to achieve to make your idea reach out the world of iOS users.',
    href: '/assets/images/aboutus/iphone-5-ma.jpg',
  },
]

const secondaryFeatures = [
  {
    name: 'Push to deploy.',
    description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'SSL certificates.',
    description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
    icon: LockClosedIcon,
  },
  {
    name: 'Simple queues.',
    description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus.',
    icon: ArrowPathIcon,
  },
  {
    name: 'Advanced security.',
    description: 'Lorem ipsum, dolor sit amet consectetur adipisicing elit aute id magna.',
    icon: FingerPrintIcon,
  },
  {
    name: 'Powerful API.',
    description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
    icon: Cog6ToothIcon,
  },
  {
    name: 'Database backups.',
    description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. ',
    icon: ServerIcon,
  },
]
</script>