import verifyEmail from "../views/front/dashboard/index";

export default[
    {
        name: 'mainPage',
        path: '/',
        component: verifyEmail,
        meta:{title:'Remoteen'}
    },
    
]


